/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./server-config.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/primeng/components/selectbutton/selectbutton.ngfactory";
import * as i4 from "primeng/components/selectbutton/selectbutton";
import * as i5 from "@angular/forms";
import * as i6 from "./server-config.component";
import * as i7 from "./server-config.service";
import * as i8 from "../../../app.service";
import * as i9 from "../../../shared/shared-common-initialization.service";
var styles_ServerConfigComponent = [i0.styles];
var RenderType_ServerConfigComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ServerConfigComponent, data: {} });
export { RenderType_ServerConfigComponent as RenderType_ServerConfigComponent };
function View_ServerConfigComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "pi pi-download"], ["style", "width:16px;vertical-align:middle"]], null, null, null, null, null))], null, null); }
function View_ServerConfigComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "pi pi-upload"], ["style", "width:16px;vertical-align:middle"]], null, null, null, null, null))], null, null); }
function View_ServerConfigComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["style", "padding: .5em 1em"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, [[2, 2]], null, 1, null, View_ServerConfigComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, [[2, 2]], null, 1, null, View_ServerConfigComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.label === "Download"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.label === "Upload"); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.label; _ck(_v, 5, 0, currVal_2); }); }
function View_ServerConfigComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "single-file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "col-md-11"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "size"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "progress-cont"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "progress"]], [[4, "width", null]], null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteFile(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["class", "fa fa-trash"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.name); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.formatBytes(((_v.context.$implicit == null) ? null : _v.context.$implicit.size)); _ck(_v, 6, 0, currVal_1); var currVal_2 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.progress) + "%"); _ck(_v, 9, 0, currVal_2); }); }
function View_ServerConfigComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "row margin-top-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "col-xs-12 col-md-8 col-sm-12 offset-md-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["appDragDrop", ""], ["class", "fileContainer col-md-12"]], null, [[null, "fileDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("fileDropped" === en)) {
        var pd_0 = (_co.onFileDropped($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["fileDropRef", 1]], null, 0, "input", [["autocomplete", "off"], ["id", "fileDropRef"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.fileBrowseHandler($event.target.files) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Drag and drop file here"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["or"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "label", [["for", "fileDropRef"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Browse for file"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "files-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServerConfigComponent_5)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 6, "div", [["class", "row margintop20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "col-md-6 col-sm-6 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-primary btn-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.acceptFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Accept"])), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["class", "col-md-6 col-sm-6 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["class", "btn btn-primary btn-sm"], ["style", "float:right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.files; var currVal_1 = _co.trackByIdx; _ck(_v, 12, 0, currVal_0, currVal_1); }, null); }
export function View_ServerConfigComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { fileDropEl: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "col-xs-12 col-md-12 col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "pi pi-question-circle piHelp"], ["id", "helpIcon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getHelpDocumentation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "col-xs-12 col-md-12 col-sm-12 justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "col-xs-10 offset-xs-1 col-sm-6 offset-sm-3 col-md-4 offset-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h3", [["class", "sectionTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Server config parameters"])), (_l()(), i1.ɵeld(9, 0, null, null, 16, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 15, "div", [["class", "col-md-10 offset-md-1 col-xs-12 col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 14, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 10, "div", [["class", "col-xs-12 col-md-12 col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 9, "div", [["class", "row margin-top-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 8, "div", [["class", "col-md-4 offset-md-4 col-xs-4 col-sm-7"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 7, "p-selectButton", [["optionLabel", "label"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selectedOptionServer = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onChange" === en)) {
        var pd_1 = (_co.serverConfigChange() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_SelectButton_0, i3.RenderType_SelectButton)), i1.ɵdid(17, 49152, null, 1, i4.SelectButton, [i1.ChangeDetectorRef], { optionLabel: [0, "optionLabel"], options: [1, "options"] }, { onChange: "onChange" }), i1.ɵqud(603979776, 2, { itemTemplate: 0 }), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.SelectButton]), i1.ɵdid(20, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(22, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵand(0, [[2, 2]], null, 0, null, View_ServerConfigComponent_1)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServerConfigComponent_4)), i1.ɵdid(25, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "label"; var currVal_8 = _co.optionsServerConfig; _ck(_v, 17, 0, currVal_7, currVal_8); var currVal_9 = _co.selectedOptionServer; _ck(_v, 20, 0, currVal_9); var currVal_10 = ((_co.selectedOptionServer !== undefined) && (_co.selectedOptionServer.value == "Upload")); _ck(_v, 25, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 22).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 22).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 22).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 22).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 22).ngClassValid; var currVal_5 = i1.ɵnov(_v, 22).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 22).ngClassPending; _ck(_v, 16, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ServerConfigComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-server-config", [], null, null, null, View_ServerConfigComponent_0, RenderType_ServerConfigComponent)), i1.ɵdid(1, 245760, null, 0, i6.ServerConfigComponent, [i7.ServerConfigService, i8.AppService, i9.SharedInitializationService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ServerConfigComponentNgFactory = i1.ɵccf("app-server-config", i6.ServerConfigComponent, View_ServerConfigComponent_Host_0, {}, {}, []);
export { ServerConfigComponentNgFactory as ServerConfigComponentNgFactory };
