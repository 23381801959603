import { T } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Injectable, ViewRef } from '@angular/core';
import { Subject } from 'rxjs';
import { SharedGeneralFunctionsService } from 'src/app/shared/shared-general-functions.service';
import { WorkflowNameService } from 'src/app/workflow/services/workflow-name.service';

@Injectable()
export class AnalysisGridSharedService {
  constructor(
    private workflowNameService: WorkflowNameService,
    private sharedService: SharedGeneralFunctionsService) {
  }

  groupedArray: { name: string }[] = [];
  groupedWithStatusArray: { name: string, status: string }[] = [];
  elementSelected: any = {};
  lastBookmarkDetailsChange = new Subject<any>();

  getSuperClassesCols(response) {
    let superClassesCols = [];
    for (var i in response) {
      var key = Object.keys(response[i])
      for (var k in key) {
        superClassesCols.push({ field: key[k] });
      }
      break;
    }
    return superClassesCols;
  }

  //this allows to format the columns for the grid
  // called in analysis grid
  getVirtualColumns(sourceColumn, selectedColumns) {
    let virtualColumns = [];

    for (var i in sourceColumn) {
      for (var j in selectedColumns) {
        if (sourceColumn[i].name === selectedColumns[j].name) {
          virtualColumns.push(sourceColumn[i])
        }
      }
    }
    return virtualColumns;
  }

  //this allows to return the virtual columns for the dettails table
  getDetailVirtualColumns(type, gridData, table) {

    var virtualColumns = [];
    var keys = Object.keys(gridData[0]);

    for (var i in keys) {
      var attribType = this.workflowNameService.getTableAttributeType(table, keys[i], this.updateBookmarkAccordingSource.name);
      // if no attrtype has been found in the workflow (new attribute created) it is 'string' as default
      let value = gridData[0][keys[i]];
      if ((type === "Aggregate") || (type === "Temporal")) {

        // if aggregate then if this.aggregationData[0][keys[j]]==null take the first not null in the first hundreds if exist             
        value = this.sharedService.getFirstValueNotNull(gridData, keys[i]);
      }
      attribType = this.sharedService.getAttributeType(value, attribType);


      let width = 140;
      if (attribType === "date") {
        width = 150;
      }
      virtualColumns.push(
        {
          key: keys[i],
          name: keys[i],
          resizable: true,
          draggable: true,
          sort: null,
          width: width,
          type: attribType
        }
      )

    }
    return virtualColumns;
  }
  //this allows to get the columns that were removed in the bookmark
  //called in analysis grid
  getRemovedColumns(sourceColumn, selectedColumns) {
    let removedCols = [];
    for (var m in sourceColumn) {
      let removed = true;
      for (var n in selectedColumns) {
        if (selectedColumns[n].name === sourceColumn[m].name) {
          removed = false;
          break;
        }
      }
      if (removed === true) {
        removedCols.push(sourceColumn[m].name)
      }
    }
    return removedCols;
  }

  //this allows to sort the grid columns
  // called in analysis grid for the show hide columns etc
  sortVirtualColumns(virtualColumns, configColumns) {
    let result = configColumns.map(a => a.name);


    virtualColumns.sort(function (a, b) {
      var A = a.name, B = b.name;

      if (result.indexOf(A) && result.indexOf(B)) {
        if (result.indexOf(A) > result.indexOf(B)) {
          return 1;
        } else {
          return -1;
        }
      }
      else {
        return -1
      }
    });
    virtualColumns = this.updateVirtualColumnsPosition(virtualColumns);
    return virtualColumns;
  }

  //this function allows to format the global filters for python
  // called in analysis grid
  updateGlobalFilters(event) {

    let globalFilters = [];
    for (var i in event.filters) {

      if ((event.filters[i].value2 != null) && (event.filters[i].value2) && (event.filters[i].value2 != "")) {
        if (event.filters[i].inputType === 'date') {
          if (event.filters[i].filterType === 'dateTo') {

            globalFilters.push(
              {
                placeholder: event.filters[i].filterType,
                term: event.filters[i].value,
                type: event.filters[i].inputType,
                condition: event.filters[i].filterCondition,
                field: event.filters[i].field,

              })
            globalFilters.push(
              {
                placeholder: 'dateFrom',
                term: event.filters[i].value2,
                type: event.filters[i].inputType,
                condition: event.filters[i].filterCondition,
                field: event.filters[i].field,

              })
          }
          else {
            globalFilters.push(
              {
                placeholder: event.filters[i].filterType,
                term: event.filters[i].value,
                type: event.filters[i].inputType,
                condition: event.filters[i].filterCondition,
                field: event.filters[i].field,

              })
            globalFilters.push(
              {
                placeholder: 'dateTo',
                term: event.filters[i].value2,
                type: event.filters[i].inputType,
                condition: event.filters[i].filterCondition,
                field: event.filters[i].field,

              })

          }
        }
        else if (event.filters[i].inputType === 'numeric') {
          event.filters[i].value = this.goodformat(event.filters[i].value);
          event.filters[i].value2 = this.goodformat(event.filters[i].value2);

          let condition = event.filters[i].filterCondition;
          if (condition === true) {
            condition = "AND"
          }
          else if (condition === false) {
            condition = "OR"
          }
          if (event.filters[i].filterType === 'greater') {
            globalFilters.push(
              {
                placeholder: event.filters[i].filterType,
                term: event.filters[i].value,
                type: event.filters[i].inputType,
                condition: condition,
                field: event.filters[i].field,

              })
            globalFilters.push(
              {
                placeholder: 'less',
                term: event.filters[i].value2,
                type: event.filters[i].inputType,
                condition: condition,
                field: event.filters[i].field,

              })
          }
          else {
            globalFilters.push(
              {
                placeholder: event.filters[i].filterType,
                term: event.filters[i].value,
                type: event.filters[i].inputType,
                condition: condition,
                field: event.filters[i].field,

              })
            globalFilters.push(
              {
                placeholder: 'greater',
                term: event.filters[i].value2,
                type: event.filters[i].inputType,
                condition: condition,
                field: event.filters[i].field,

              })
          }
        }
      }
      else {
        if (event.filters[i].inputType === 'numeric') {
          event.filters[i].value = this.goodformat(event.filters[i].value);
        }

        let condition = event.filters[i].filterCondition;
        if (condition === true) {
          condition = "AND"
        }
        else if (condition === false) {
          condition = "OR"
        }
        globalFilters.push(
          {
            placeholder: event.filters[i].filterType,
            term: event.filters[i].value,
            type: event.filters[i].inputType,
            condition: condition,
            field: event.filters[i].field,

          }
        )
      }
    }
    return globalFilters;
  }

  // this function allows to get the bookmark configuration when returned from the service
  // called in analysis grid
  getBookmarksConfig(data) {
    for (var i in data) {
      if (typeof data[i].config === "string") {
        data[i].config = JSON.parse(data[i].config);
      }
      if (typeof data[i].filters === "string") {
        data[i].filters = JSON.parse(data[i].filters);
      }
      if (typeof data[i].globalFilters === "string") {
        data[i].globalFilters = JSON.parse(data[i].globalFilters);
      }
      if (typeof data[i].pinnedCols === "string") {
        data[i].pinnedCols = JSON.parse(data[i].pinnedCols);
      }
      if (typeof data[i].numericFormat === "string") {
        data[i].numericFormat = JSON.parse(data[i].numericFormat)
      }
      if (typeof data[i].removedCols === "string") {
        data[i].removedCols = JSON.parse(data[i].removedCols);
      }
      if (typeof data[i].globalFiltersForGrid === "string") {
        data[i].globalFiltersForGrid = JSON.parse(data[i].globalFiltersForGrid);
      }
      if (typeof data[i].globalSorts === "string") {
        data[i].globalSorts = JSON.parse(data[i].globalSorts)
      }
      if (typeof data[i].mainGlobalFilters === "string") {
        data[i].mainGlobalFilters = JSON.parse(data[i].mainGlobalFilters)
      }
    }
    return data;
  }

  //get bookmarks config without last bookmark
  //called in analysis grid
  getBookmarksConfigForEditDelete(param, oldBookmarksConfig) {

    let bookmarksConfig = [];
    bookmarksConfig = JSON.parse(JSON.stringify(oldBookmarksConfig));

    for (var i in bookmarksConfig) {
      if (bookmarksConfig[i].name === "lastBookmark") {
        bookmarksConfig.splice(+i, 1)
      }
    }

    return bookmarksConfig;

  }

  //this allows to cross check whether attributes exist or not while changing bookmarks
  updateBookmarkAccordingSource(selectedBookmark, sourceColumn, removedCols) {

    for (var i in sourceColumn) {
      let attributeExists = false;
      for (var j in selectedBookmark.config.column) {
        if (selectedBookmark.config.column[j].name === sourceColumn[i].name) {
          attributeExists = true;
          break;
        }
      }
      if (attributeExists === false) {
        for (var k in removedCols) {
          if (sourceColumn[i].name === removedCols[k]) {
            attributeExists = true;
            break;
          }
        }
      }

      if (attributeExists === false) {
        selectedBookmark.config.column.push(sourceColumn[i]);
      }
    }
    // add agnes 27/05
    // if more attributes in bookmark than in source then remove them from selected bookmark
    for (var s = selectedBookmark.config.column.length - 1; s >= 0; s--) {
      //for (var o in this.selectedBookmark.value.config.column) {
      let attributeExists = false;
      for (var n in sourceColumn) {
        if (sourceColumn[n].name === selectedBookmark.config.column[s].name) {
          attributeExists = true;
          break;
        }
      }
      if (attributeExists === false) {
        selectedBookmark.config.column.splice(s, 1);
      }
    }
    //end add

    return selectedBookmark;
  }

  //thsi function allows to find the show/hide columns
  getSelectedToggleCols(toggleColumns, selectedBookmark) {
    let selectedToggleCols = [];
    for (var n in toggleColumns) {
      for (var o in selectedBookmark.config.column) {
        if (toggleColumns[n].name === selectedBookmark.config.column[o].name) {
          selectedToggleCols.push(toggleColumns[n])
        }
      }
    }
    return selectedToggleCols;

  }

  //this function allows to update the last bookmark config
  updateLastBookmarkConfig(bookmarksConfig, globalFilters, globalFiltersForGrid, activeGlobalFilter, removedCols, lastBookmarkConfig, globalSorts, mainFiltersInfoArray) {
    // //globalFilters,globalFiltersForGrid are empty and activeGlobalFilter=false when aggregatedetails or temporaldetails
    // bookmarksConfig = this["bookmarks" + type + "Config"] // aggregate or temporal
    if (bookmarksConfig.length != 0) {
      for (var i in bookmarksConfig) {
        if (bookmarksConfig[i].name === "lastBookmark") {
          bookmarksConfig[i].config = lastBookmarkConfig.config;
          bookmarksConfig[i].globalFilters = globalFilters;
          bookmarksConfig[i].globalFiltersForGrid = globalFiltersForGrid;
          bookmarksConfig[i].global = activeGlobalFilter;
          bookmarksConfig[i].pinnedCols = lastBookmarkConfig.pinnedCols;
          bookmarksConfig[i].filters = lastBookmarkConfig.filters;
          bookmarksConfig[i].numericFormat = lastBookmarkConfig.numericFormat;
          bookmarksConfig[i].removedCols = removedCols;
          bookmarksConfig[i].globalSorts = globalSorts;
          bookmarksConfig[i].mainGlobalFilters = mainFiltersInfoArray;
        }
      }
    }
    else {
      bookmarksConfig.push({
        name: "lastBookmark",
        config: lastBookmarkConfig.config,
        filters: lastBookmarkConfig.filters,
        globalFilters: globalFilters,
        globalFiltersForGrid: globalFiltersForGrid,
        global: activeGlobalFilter,
        pinnedCols: lastBookmarkConfig.pinnedCols,
        numericFormat: lastBookmarkConfig.numericFormat,
        removedCols: removedCols,
        globalSorts: globalSorts,
        mainGlobalFilters: mainFiltersInfoArray
      })
    }

    return bookmarksConfig;
  }

  //this ufnction allows to update the bookmark config or add a new bookmark
  updateBookmarkConfig(bookmarksConfig, globalFilters, globalFiltersForGrid, activeGlobalFilter, removedCols, newBookmark, newBookmarkName, oldBookmarkName, newBookmarkParam, globalSorts, mainFiltersInfoArray) {
    //globalFilters,globalFiltersForGrid are empty and activeGlobalFilter=false when aggregatedetails or temporaldetails
    if (newBookmarkParam === "new") {
      bookmarksConfig.push({
        name: newBookmarkName,
        config: newBookmark.config,
        filters: newBookmark.filters,
        globalFilters: globalFilters,
        globalFiltersForGrid: globalFiltersForGrid,
        global: activeGlobalFilter,
        pinnedCols: newBookmark.pinnedCols,
        numericFormat: newBookmark.numericFormat,
        removedCols: removedCols,
        globalSorts: globalSorts,
        mainGlobalFilters: mainFiltersInfoArray
      })
    }
    else {
      for (var i in bookmarksConfig) {
        //if (bookmarksConfig[i].name === selectedEditBookmark.value.name) {
        if (bookmarksConfig[i].name === oldBookmarkName) {
          bookmarksConfig[i].config = newBookmark.config;
          bookmarksConfig[i].filters = newBookmark.filters;
          bookmarksConfig[i].globalFilters = globalFilters;
          bookmarksConfig[i].globalFiltersForGrid = globalFiltersForGrid;
          bookmarksConfig[i].global = activeGlobalFilter;
          bookmarksConfig[i].pinnedCols = newBookmark.pinnedCols;
          bookmarksConfig[i].numericFormat = newBookmark.numericFormat;
          bookmarksConfig[i].removedCols = removedCols;
          bookmarksConfig[i].globalSorts = globalSorts;
          bookmarksConfig[i].mainGlobalFilters = mainFiltersInfoArray;
        }
      }
    }
    return bookmarksConfig;
  }

  //this allows to return the good numeric format without letters
  goodformat(value) {

    if (typeof value === "string") {
      if (value.toLowerCase().indexOf("k") > -1) {
        value = value.substr(0, value.toLowerCase().indexOf('k'));
        value = + value * 1000;
      }
      else if (value.toLowerCase().indexOf("m") > -1) {
        value = value.substr(0, value.toLowerCase().indexOf('m'));
        value = + value * 1000000;
      }
    }

    return value;
  }

  //this allows to set the temporal subtable thresholds
  setTemporalSubtableThresholds(temporalTable) {
    let detectarray = [];

    if (temporalTable.Steps["Models"]) {
      let allModels = temporalTable.Steps["Models"].Models
      for (var model in allModels) {
        var newdata = { name: "", threshold: 0 };
        newdata.name = allModels[model]["model"];
        for (var j in allModels[model]["values"]) {
          if (allModels[model]["values"][j]['Variable'] == 'detection_threshold') {
            newdata.threshold = allModels[model]["values"][j]['Value'];
            detectarray.push(newdata);
            break;
          }
        }
      }
    }

    return detectarray;
  }

  //this allows to move the columns true status, ture status type, typeofanomaly and fieldid at the front of the grid
  moveInArray(virtualColumns, fieldID, type, rec_group) {

    var to = 1;
    var from = 0;
    for (var i in virtualColumns) {
      if (virtualColumns[i].name === "TrueStatus") {
        to = 0;
        from = +i;
        virtualColumns = this.sharedService.moveInArray(virtualColumns, to, from);

      }
      else if (virtualColumns[i].name === "TrueStatusType") {
        to = 1;
        from = +i;
        virtualColumns = this.sharedService.moveInArray(virtualColumns, to, from);
      }
      else if (virtualColumns[i].name === "typeOfAnomaly") {
        to = 2;
        from = +i;
        virtualColumns = this.sharedService.moveInArray(virtualColumns, to, from);
      }
      else if ((type === "Matching") && (virtualColumns[i].name === "cardabel_rec")) {
        to = 0;
        from = +i;
        virtualColumns = this.sharedService.moveInArray(virtualColumns, to, from);
      }
      else if ((type === "Matching") && (virtualColumns[i].name === rec_group)) {
        to = 0;
        from = +i;
        virtualColumns = this.sharedService.moveInArray(virtualColumns, to, from);
      }
      else if (virtualColumns[i].name === fieldID) {
        to = 3;
        from = +i;
        virtualColumns = this.sharedService.moveInArray(virtualColumns, to, from);
      }
    }

    virtualColumns = this.updateVirtualColumnsPosition(virtualColumns)

    return virtualColumns;

  }

  updateVirtualColumnsPosition(virtualColumns) {
    let newWidth = 2;
    for (var i in virtualColumns) {
      newWidth = newWidth + virtualColumns[i].width;
      let hidden = false;
      /*  if ((newWidth > 3500) && (virtualColumns.length > 41)) {
          hidden = true;
        } */
      virtualColumns[i].position = newWidth;
      virtualColumns[i].hidden = hidden;
    }
    return virtualColumns
  }

  moveInArrayGauss(virtualColumns) {
    var to = 1;
    var from = 0;
    for (var i in virtualColumns) {
      if (virtualColumns[i].name.indexOf("Proba") > -1) {
        to = 0;
        from = +i;
        virtualColumns = this.sharedService.moveInArray(virtualColumns, to, from);
      }
      else if (virtualColumns[i].name.indexOf("Predicted") > -1) {
        to = 0;
        from = +i;
        virtualColumns = this.sharedService.moveInArray(virtualColumns, to, from);
      }

    }
    virtualColumns = this.updateVirtualColumnsPosition(virtualColumns)

    return virtualColumns;
  }
  //this allows to trigger the last bookmark details change
  setLastBookmarkDetailsChange() {
    //need to save this config as lastbookmark;
    this.lastBookmarkDetailsChange.next();
  }

  // return the order of the columns as it is in config
  // used for export
  getColumnsOrder(config) {

    var columnsOrder = [];
    for (var i in config.column) {
      let columnname = config.column[i].name;
      if ((columnname.length > 0) && (config.column[i].type !== "index")) {
        columnsOrder.push(columnname);
      }
    }
    return columnsOrder;
  }

  // reorder the attribute of the checkedrows with the new columnsOrder
  // used for export selected
  getRowsOrdered(checkedRows, columnsOrder) {
    let checkedRowsOrdered = []
    console.log("orrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr");
    let count = 0;
    for (var i in columnsOrder) {
      if (columnsOrder[i].toLowerCase() == "cardabel_score") {
        count++;
      }
    }
    if (count < 2) {
      for (var c in checkedRows) {
        let newdata = {};
        for (var i in columnsOrder) {
          newdata[columnsOrder[i]] = checkedRows[c][columnsOrder[i]];
        }
        checkedRowsOrdered.push(newdata);
      }
    }
    else {
      for (var c in checkedRows) {
        let newdata = {};
        for (var i in columnsOrder) {
          if (columnsOrder[i] != "cardabel_score") {
            newdata[columnsOrder[i]] = checkedRows[c][columnsOrder[i]];
          }
        }
        checkedRowsOrdered.push(newdata);
      }
    }
    return checkedRowsOrdered;
  }

  //this is to get the operator for matching subtables
  getMatchingOperator(mode) {
    let matchingArray = [{
      mode: "Train",
      operator: "Features 1"
    },
    {
      mode: "Test",
      operator: "Test"
    },
    {
      //22062022
      mode: "Predict",
      operator: "Test"
    },
    {
      mode: "Request",
      operator: "Scenario"
    },
    {
      mode: "All",
      operator: "All"
    },
    {
      mode: "Histo",
      operator: "Histo"
    }]

    return matchingArray.find(item => item.mode === mode).operator;
  }


  //this is to format filters info array into main global filters
  transformMainGlobalFilters(filtersInfoArray) {
    let array = [];

    for (var i in filtersInfoArray) {

      if (filtersInfoArray[i].Attribute.AttrName != '') {
        let newdata;

        if (filtersInfoArray[i].Attribute.AttrType === "string") {
          newdata = {
            field: filtersInfoArray[i].Attribute.AttrName,
            type: filtersInfoArray[i].Attribute.AttrType,
            term: filtersInfoArray[i].Filter.Term,
            placeholder: filtersInfoArray[i].Filter.Operator,
            condition: undefined
          };
          array.push(newdata)
        }
        else if (filtersInfoArray[i].Attribute.AttrType === "date") {
          newdata = {
            field: filtersInfoArray[i].Attribute.AttrName,
            type: filtersInfoArray[i].Attribute.AttrType,
            term: filtersInfoArray[i].Filter.End,
            placeholder: "dateTo",
            condition: undefined
          };
          array.push(newdata)
          newdata = {
            field: filtersInfoArray[i].Attribute.AttrName,
            type: filtersInfoArray[i].Attribute.AttrType,
            term: filtersInfoArray[i].Filter.Start,
            placeholder: "dateFrom",
            condition: undefined
          };
          array.push(newdata)
        }
        else if (filtersInfoArray[i].Attribute.AttrType === "numeric") {
          newdata = {
            field: filtersInfoArray[i].Attribute.AttrName,
            type: filtersInfoArray[i].Attribute.AttrType,
            term: filtersInfoArray[i].Filter.Min,
            placeholder: "greater",
            condition: undefined
          };
          array.push(newdata)
          newdata = {
            field: filtersInfoArray[i].Attribute.AttrName,
            type: filtersInfoArray[i].Attribute.AttrType,
            term: filtersInfoArray[i].Filter.Max,
            placeholder: "less",
            condition: undefined
          };
          array.push(newdata)
        }

      }

    }

    return array;


  }

  //column charts

  showColumnChart(columnChart, header, data, gridData) {
    columnChart.data = [];
    columnChart.gridData = [];

    let title = header;

    columnChart.chartHeader = header;

    if (data.length != 0) {
      columnChart.data = [...data];

      title = header + "- in local page."
    }
    if (gridData.length != 0) {
      columnChart.gridData = [...gridData];
    }

    columnChart.header = header;
    return columnChart
  }
}