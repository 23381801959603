import { Injectable } from '@angular/core';
import { element } from '@angular/core/src/render3';
import { parseTemplate } from '@angular/compiler';

@Injectable()
export class SharedGeneralFunctionsService {
  groupedArray: { name: string }[] = [];
  groupedWithStatusArray: { name: string, status: string }[] = [];
  elementSelected: any = {};

  //this is to check whether an element already exists in an array of objects
  //called in email config, true status types, new workflow, active attributes and workflow components
  elementExists(array, key, element) {
    return array.some(function (el) {
      return el[key] === element;
    })
  }

  //this is to check if an element with two different keys exists
  //called in true status type component
  elementsExist(array, key1, key2, element1, element2) {
    return array.some(function (el) {
      return el[key1] === element1 && el[key2] === element2;
    })
  }

  //get an array with unique key
  //called in analysis and workflwo component
  getArrayWithUniqueKey(array, groupKey) {

    this.groupedArray = [];

    for (var i in array) {
      if (this.elementExists(this.groupedArray, "name", array[i][groupKey]) === false) {
        this.groupedArray.push({ name: array[i][groupKey] })
      }

    }
    console.log(this.groupedArray);

    return this.groupedArray;


  }
  /*
    //returns filtered array according to chosen filter
    //called in analysis grid component
   // filterArrayOfObjects(array, filter) {
      var keys = Object.keys(filter);
  
      array = array.filter(function (item) {
        for (var i in keys) {
          if (filter[keys[i]].matchMode === 'isEmpty') {
            if (item[keys[i]] != undefined) {
              return false;
            }
          }
          else if (filter[keys[i]].matchMode === 'isNotEmpty') {
            if (item[keys[i]] === undefined) {
              return false;
            }
          }
          else if (filter[keys[i]].matchMode === 'contains') {
            if (item[keys[i]].indexOf(filter[keys[i]].value) === -1) {
              return false;
            }
          }
          else if (filter[keys[i]].matchMode === 'doesNotContain') {
            if (item[keys[i]].indexOf(filter[keys[i]].value) > -1) {
              return false;
            }
          }
          else if (filter[keys[i]].matchMode === 'equals') {
            if (item[keys[i]] != filter[keys[i]].value) {
              return false;
            }
          }
          else if (filter[keys[i]].matchMode === 'doesNotEqual') {
            if (item[keys[i]] === filter[keys[i]].value) {
              return false;
            }
          }
        }
        return true;
      });
      console.log(array)
      return array;
  
    }*/

  //returns all the properties of the selected element
  //called in aggreg, post aggreg, short long, new dim components
  getElementSelected(array, key, selected) {
    this.elementSelected = {};
    let array2 = JSON.parse(JSON.stringify(array));

    array2 = array2.filter(function (item) {
      if (item[key] === selected) {
        return item;
      }
    });
    if (array2.length === 0) {
      this.elementSelected[key] = "";
    }
    else {
      this.elementSelected = array2[0];
    }

    return this.elementSelected;
  }

  //allows to sort array of object
  //called in aggreg, cycle, measures, short long, detect simple, gauss attr, info attr, active attr, filters, new dim components 
  compareAttribute(a, b) {

    var A = a.AttrName.toLowerCase();
    var B = b.AttrName.toLowerCase();
    if (A < B) {
      return -1;
    } else if (A > B) {
      return 1;
    } else {
      return 0;
    }

  }

  //allows to sort array of object
  //called in aggreg, cycle, measures, short long, detect simple, gauss attr, info attr, active attr, filters, new dim components 
  compareAttributeByType(a, b) {

    var A = a.AttrType.toLowerCase();
    var B = b.AttrType.toLowerCase();
    if (A < B) {
      return -1;
    } else if (A > B) {
      return 1;
    } else {
      return 0;
    }

  }
  compareAttributeByValue(a, b) {

    var A = a.AttrValue.toLowerCase();
    var B = b.AttrValue.toLowerCase();
    if (A < B) {
      return -1;
    } else if (A > B) {
      return 1;
    } else {
      return 0;
    }

  }
  //allows to sort array of object
  //called in step attr status
  compareAttributeByStatus(a, b) {

    var A = a.AttrStatus.toLowerCase();
    var B = b.AttrStatus.toLowerCase();
    if (A < B) {
      return -1;
    } else if (A > B) {
      return 1;
    } else {
      return 0;
    }

  }
  //called in step attr status
  compareAttributeByStatusBool(a, b) {

    var A = a.AttrStatusBool.toString().toLowerCase();
    var B = b.AttrStatusBool.toString().toLowerCase();
    // true before false
    if (A < B) {
      return 1;
    } else if (A > B) {
      return -1;
    } else {
      return 0;
    }

  }
  //////////// for 3D plot  numeric/// 
  compareCategorie(a, b) {

    let key = "Categorie";
    if ((typeof (a[key]) === "number") && (typeof (b[key]) === "number"))
      return a[key] - b[key];
    else {
      if (typeof (a[key]) != "number")
        return -1;
      else {
        if ((a[key] === null) || isNaN(a[key])) {
          return -1;
        }
        else {
          return 1;
        }
      }
    }

  }
  // get the headers and order them :"labels" first
  getMatrixColumns(data) {

    let matrixColumns = [];

    for (var j in data[0]) {
      let newdata = { Variable: "" };
      newdata.Variable = j;
      matrixColumns.push(newdata);
    }
    matrixColumns = matrixColumns.sort(this.compareLabel);

    return matrixColumns;
  }
  compareLabel(a, b) {
    var A = a.Variable.toLowerCase();
    var B = b.Variable.toLowerCase();
    if (A === "labels") {
      return -1;
    }
    else {
      return 1;
    }
  }
  //removes an attribute in array
  //called in aggreg, cycle, detect simple, gauss attr, info attr components
  removeAttributesFromList(removelist, fromlist) {
    if (removelist.length > 0) {
      for (var j in removelist) {
        var name = removelist[j].AttrName;
        for (var i = fromlist.length - 1; i >= 0; i--) {
          if (fromlist[i].AttrName === name) fromlist.splice(i, 1);
        }
      }
    }
    return fromlist;
  }

  //allows to switch elements' place
  //called in analysis grid
  moveInArray(array, to, from) {
    if (to === from) return array;

    var target = array[from];
    var increment = to < from ? -1 : 1;

    for (var k = from; k != to; k += increment) {
      array[k] = array[k + increment];
    }
    array[to] = target;
    return array;


  };

  arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length)
      return false;
    arr1.sort();
    arr2.sort();
    for (var i = arr1.length; i--;) {
      if (arr1[i] !== arr2[i])
        return false;
    }

    return true;
  }

  objectsEqual(value, other) {

    // Get the value type
    var type = Object.prototype.toString.call(value);

    // If the two objects are not the same type, return false
    if (type !== Object.prototype.toString.call(other)) return false;

    // If items are not an object or array, return false
    if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

    // Compare the length of the length of the two items
    var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
    var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
    if (valueLen !== otherLen) return false;



    // Compare properties
    if (type === '[object Array]') {
      for (var i = 0; i < valueLen; i++) {
        if (this.compare(value[i], other[i]) === false) return false;
      }
    } else {
      for (var key in value) {
        if (value.hasOwnProperty(key)) {
          if (this.compare(value[key], other[key]) === false) return false;
        }
      }
    }

    // If nothing failed, return true
    return true;

  };

  // Compare two items
  compare(item1, item2) {

    // Get the object type
    var itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!this.objectsEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {

      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }

    }
  };
  /*  objectsEqual(o1, o2) {
      if ((typeof o1 === 'object') && (typeof o2 === "object")) {
        if (Object.keys(o1).length === Object.keys(o2).length) {
          let keys1 = Object.keys(o1);
          for (var k in keys1) {
            if (o1[keys1[k]] != o2[keys1[k]]  ) {
              return false;
            }
          }
          return true;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    } */
  /*objectsEqual = (o1, o2) =>
    typeof o1 === 'object' ? (Object.keys(o1).length > 0
      ? Object.keys(o1).length === Object.keys(o2).length
      && Object.keys(o1).every(p => this.objectsEqual(o1[p], o2[p]))
      : o1 === o2 ) : o2 === null ? o1 === o2 : o1 != o2; */

  getFirstValueNotNull(data, key) {// for aggregate grid or aggregate details
    let value;
    if (data.length != 0) {
      value = data[0][key];
      // this.gridData[0][keys[j]]==null take the first not null in the first hundreds if exist

      if (value == null) {
        for (var g = 0; g < Math.min(data.length, 100); g++) {
          if ((data[g][key] !== null) && (data[g][key] != undefined)) {
            value = data[g][key];
            break;
          }
        }
      }
    }

    return value;
  }
  getAttributeType(value, attribType) {


    // if no attrtype has been found in the workflow (new attribute created) it is 'string' as default
    if ((attribType === "string") && (typeof (value) === "number")) {
      attribType = "numeric";
    }
    if (attribType === "string") {
      if (value != null) {
        if (typeof (value) !== "boolean") {

          var dateWrapper = new Date(value);
          if (!isNaN(dateWrapper.getDate())) {

            // test if good format yyyy-mm-dd

            let result = true;
            if (value.length < 8 || value.length == 9) {
              result = false;
            }
            else {
              if (value.length == 10) {
                result = this.testgoodformatDate(value);
              }
              else {
                if (value.length == 8) {
                  result = this.testgoodformatTime(value);
                }
                else if (typeof value === "object") {
                  result = true;
                }
                else {
                  //value.length>10
                  let firstpart = value.substr(0, 10);
                  result = this.testgoodformatDate(firstpart);
                }
              }
            }
            if (result == true) {
              attribType = "date";
            }
          }
        }
      }
    }
    return attribType;
  }

  removeTimeZone(data) {
    if (data.length != 0) {
      let keys = Object.keys(data[0]);
      for (var k in keys) {
        let value = this.getFirstValueNotNull(data, keys[k]);
        let attribType = this.getAttributeType(value, "string")
        if (attribType === "date") {
          for (var i in data) {
            let date = new Date(data[i][keys[k]]);
            var userTimezoneOffset = date.getTimezoneOffset() * 60000;
            console.log(date.getTimezoneOffset());
            console.log(userTimezoneOffset)
            //  console.log(userTimezoneOffset);
            data[i][keys[k]] = new Date(date.getTime() - userTimezoneOffset);

          }
        }
      }

    }

    return data;
  }
  // test if good format yyyy-mm-dd or yyyy/mm/dd
  testgoodformatDate(value) {
    let result = true;
    var patt = /[-/]/g;
    let firstseparator = value.substr(4, 1);
    let secondseparator = value.substr(7, 1);
    if ((firstseparator.match(patt) == null) || (secondseparator.match(patt) == null) || (firstseparator !== secondseparator)) {
      result = false;
    }
    else {
      let firstpart = value.substr(0, 4);
      let secondpart = value.substr(5, 2);
      let thirdpart = value.substr(8, 2);
      if ((this.testifnumeric(firstpart)) && (this.testifnumeric(secondpart)) && (this.testifnumeric(thirdpart))) { }
      else { result = false; }
    }
    return result;
  }
  // test if good format HH:MM:SS 
  testgoodformatTime(value) {
    let result = true;
    var patt = /[:]/g;
    let firstseparator = value.substr(2, 1);
    let secondseparator = value.substr(5, 1);
    if ((firstseparator.match(patt) == null) || (secondseparator.match(patt) == null) || (firstseparator !== secondseparator)) {
      result = false;
    }
    else {
      let firstpart = value.substr(0, 2);
      let secondpart = value.substr(3, 2);
      let thirdpart = value.substr(6, 2);
      if ((this.testifnumeric(firstpart)) && (this.testifnumeric(secondpart)) && (this.testifnumeric(thirdpart))) { }
      else { result = false; }
    }
    return result;
  }
  testifnumeric(part) {
    let result = true;
    var patt1 = /[0-9]/g;
    for (var s = 0; s < part.length; s++) {
      let car = part[s]
      var res = car.match(patt1);
      if (res == null) {
        result = false;
        break;
      }
    }
    return result;
  }
  removeDuplicates(array, field) {
    let newarray = []
    for (var i = 0; i < array.length; i++) {
      let fieldtocompare = array[i][field];
      let found = false;
      for (var j = 0; j < newarray.length; j++) {
        if (newarray[j][field] === fieldtocompare) {
          found = true;
          break;
        }
      }
      if (!found) {
        newarray.push(array[i]);
      }
    }

    return newarray;
  }

  // tooltip to remember the steps filters applied on the table
  getTableFiltersTooltip(tableFilter) {
    var tooltip = ''
    if (tableFilter) {
      var filters = tableFilter["Filters"];
      for (var i in filters) {
        let operator = filters[i].Filter.Operator;
        if (operator === "!") {
          operator = "\u2260";
        }
        tooltip = tooltip + filters[i].FilterName + ": " + filters[i].Attribute.AttrName + " ";
        if (filters[i].Attribute.AttrType === "string") {
          tooltip = tooltip + operator + " " + filters[i].Filter.Term + '\n';
        }
        else {
          if (filters[i].Attribute.AttrType === "numeric") {
            tooltip = tooltip + '\n' + " min: " + " " + filters[i].Filter.Min + '\n';
            tooltip = tooltip + " max: " + " " + filters[i].Filter.Max + '\n';
          }
          if (filters[i].Attribute.AttrType === "date") {
            tooltip = tooltip + '\n' + " from: " + " " + filters[i].Filter.Start + '\n';
            tooltip = tooltip + " to: " + " " + filters[i].Filter.End + '\n';
          }
        }

      }
      if (tableFilter["WhereClause"]) {
        if (tableFilter["WhereClause"].length > 0) {
          tooltip = tooltip + '\n' + "WhereClause : " + tableFilter["WhereClause"];
        }
      }
    }

    return tooltip;
  }
  // chnage date string to date
  initFiltersInfoArray(varfiltersInfoArray) {
    console.log("init");
    for (var i in varfiltersInfoArray) {
      if (varfiltersInfoArray[i].Attribute.AttrType === 'date') {
        if ((varfiltersInfoArray[i].Filter.Start !== null) && (varfiltersInfoArray[i].Filter.Start !== "")) {
          varfiltersInfoArray[i].Filter.Start = new Date(JSON.parse(JSON.stringify(varfiltersInfoArray[i].Filter.Start)));
        }
        if ((varfiltersInfoArray[i].Filter.End !== null) && (varfiltersInfoArray[i].Filter.End !== "")) {
          varfiltersInfoArray[i].Filter.End = new Date(JSON.parse(JSON.stringify(varfiltersInfoArray[i].Filter.End)));
        }
      }
      if (varfiltersInfoArray[i].Attribute.AttrType === 'string') {
        if (varfiltersInfoArray[i].Filter.Transform === undefined) {
          varfiltersInfoArray[i].Filter.Transform = '=';
        }
      }
    }

    return varfiltersInfoArray;
  }
  initParamsInfoArray(varfiltersInfoArray) {
    console.log("init");
    for (var i in varfiltersInfoArray) {
      if ((varfiltersInfoArray[i].Attribute.AttrSpec === 'date') || (varfiltersInfoArray[i].Attribute.AttrSpec === 'time')) {
        varfiltersInfoArray[i].Filter.Operator = '=';
      }
      if (varfiltersInfoArray[i].Attribute.AttrType === 'string') {
        if (varfiltersInfoArray[i].Filter.Transform === undefined) {
          varfiltersInfoArray[i].Filter.Transform = '=';
        }
      }
      if (varfiltersInfoArray[i].Filter !== undefined) {
        if (!varfiltersInfoArray[i].Filter.Strict) {
          varfiltersInfoArray[i].Filter.Strict = false;
        }
      }
    }

    return varfiltersInfoArray;
  }

  getTooltipAggregate(subtable) {
    let tooltip = "";

    if ((subtable) && (subtable.AggregateName)) {
      let filter = "none"
      if (subtable.Steps["Filters"].Filters.length > 0) {
        filter = "Active";
      }

      let aggregby = '\n- ' + subtable.Steps["Aggreg"].AggregBy.toString();
      for (var i in subtable.Steps["Aggreg"].Attributes) {
        aggregby = aggregby + '\n-' + subtable.Steps["Aggreg"].Attributes[i].AttrName;
      }
      let tempKey = subtable.Steps["Aggreg"].TempKey.Date.toString();
      let tempkeyTime = subtable.Steps["Aggreg"].TempKey.Time;
      if (tempkeyTime != "") {
        tempKey = tempKey + '\n' + tempkeyTime;
      }
      let measures = "";
      for (var i in subtable.Steps["Measures"].MeasureFields) {
        measures = measures + '\n- ' + subtable.Steps["Measures"].MeasureFields[i].Attribute.AttrName;
      }
      let cycle = subtable.Steps["Cycle"].Type;
      let superCycle = "none"
      if (subtable.Steps["Super Cycle"].Attributes.length > 0) {
        superCycle = "Active";
      }
      tooltip = "Filter: " + filter + "\n Aggregation by: " + aggregby + '\n Temporal key: ' + tempKey;
      tooltip = tooltip + "\n Measures: " + measures;
      tooltip = tooltip + "\n Cycle: " + cycle + '\n Super cycle: ' + superCycle;
    }
    return tooltip;
  }

  isWithSubtables(type: string) {
    if (type) {
      //if ((type.toLowerCase() !== 'bayes') && (type.toLowerCase() !== 'supervised')) {
      if ((type.toLowerCase() !== 'bayes')&& (type.toLowerCase() !== 'kernel')) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }

  }
}
