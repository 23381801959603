import * as tslib_1 from "tslib";
import { HttpParams, HttpHeaders, HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppService } from "src/app/app.service";
import { CustomHttpParamEncoder } from "src/app/shared/custom-http-param-encoder";
var MatchingService = /** @class */ (function () {
    function MatchingService(appService, httpClient) {
        this.appService = appService;
        this.httpClient = httpClient;
        this.gridData = {};
        //this is to change bookmarks both main and details
        this.bookmarkDataChange = {};
        this.gridDataChange = {};
        this.fieldIDChange = {};
        this.gridTotalRecordsChange = {};
        this.fieldID = {};
        this.multiSelectChange = {};
        this.matchingCheckedRowChange = {};
        this.varianceChange = {};
        this.ongoingModificationChange = {};
        this.accountNumberChange = {};
        this.varianceThresholdChange = {};
        this.varianceThreshold = {};
        this.localFiltersChange = {};
        this.showMenu = {};
        this.url = this.appService.getUrl();
    }
    MatchingService.prototype.initMatchingTabChanges = function (tabs) {
        for (var i in tabs) {
            this.showMenu[tabs[i].name] = true;
            this.gridDataChange[tabs[i].name] = new Subject();
            this.gridTotalRecordsChange[tabs[i].name] = new Subject();
            this.fieldIDChange[tabs[i].name] = new Subject();
            this.bookmarkDataChange[tabs[i].name] = new Subject();
            this.matchingCheckedRowChange[tabs[i].name] = new Subject();
            this.multiSelectChange[tabs[i].name] = new Subject();
            this.varianceChange[tabs[i].name] = new Subject();
            this.ongoingModificationChange[tabs[i].name] = new Subject();
            this.accountNumberChange[tabs[i].name] = new Subject();
            this.varianceThresholdChange[tabs[i].name] = new Subject();
            this.varianceThreshold[tabs[i].name] = 0.001;
            this.localFiltersChange[tabs[i].name] = new Subject();
        }
    };
    //  withCharts is a boolean that indicates if the table counts must be done or not for the charts
    // withLoad is a boolean that indicates if the table must be loaded or not
    MatchingService.prototype.getMatchingLoadGrid = function (workflow, table, subTable, startRec, endRec, mode, proMode, filters, sorts, withLoad, withCharts, matchingDetails, tab) {
        var _this = this;
        this.appService.startSpin2();
        // this.appService.startSpin();
        var url = this.url;
        var webservice = "AnalysisLoadTables";
        var completeUrl = url + webservice;
        if (filters === undefined) {
            filters = "[]";
        }
        if (sorts === undefined) {
            sorts = "[]";
        }
        var varFilter = "{\"listOfFilters\":" + filters + "}";
        //let varFilter2 = encodeURIComponent(varFilter);
        var varSort = "{\"listOfSorts\":" + sorts + "}";
        //create new http params
        var params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", workflow)
            .set("segAttrValue", table)
            .set("subTable", subTable)
            //.set("fieldID", fieldID)
            .set("startRec", startRec)
            .set("endRec", endRec)
            .set("mode", mode)
            .set("proMode", proMode)
            .set("Filter", varFilter)
            .set("Sort", varSort)
            .set("withLoad", withLoad)
            .set("withCharts", withCharts);
        var headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.httpClient.post(completeUrl, params, { headers: headers, responseType: 'text', withCredentials: true })
            .pipe(
        //map(response => new NmUser(response)),
        catchError(this.appService.handleError))
            .subscribe(function (response) {
            //response = response.replace(/[()]/g, '');
            response = response.substring(1, response.length - 1);
            response = JSON.parse(response);
            if (response.statusCode > -1) {
                if (matchingDetails === false) {
                    if (withLoad) {
                        _this.gridTotalRecordsChange[tab].next(response.tableRows2[0].TotalRecords);
                        response.tableRows = response.tableRows.map(function (el, i) {
                            return tslib_1.__assign({}, el, { matchingGuiIndex: +i });
                        });
                        _this.gridData[tab] = response.tableRows;
                        _this.gridDataChange[tab].next(response.tableRows);
                    }
                    //
                }
            }
            else {
                _this.appService.showMessage('Error', response.statusText);
            }
            _this.appService.stopSpin2();
            // this.appService.stopSpin();
        }, function (error) {
            _this.appService.showMessage('Error', error.statusText);
            //  this.appService.stopSpin();
            _this.appService.stopSpin2();
        });
    };
    MatchingService.prototype.getMatchingAccountNumberValues = function (tab) {
        var _this = this;
        this.appService.startSpin2();
        // this.appService.startSpin();
        var subtabletype = tab.matchingType;
        if (tab.matchingType === "Test") {
            subtabletype = "predicted";
        }
        var url = this.url;
        var webservice = "WFBaseTableGetAttributeValuesSubTable";
        var completeUrl = url + webservice;
        //create new http params
        var params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", tab.workflow)
            .set("segAttrValue", tab.segAttributeValue)
            .set("subTableName", tab.subtable)
            .set("subTableType", subtabletype)
            .set("attrName", tab.accountNumber)
            .set("attrType", "string");
        var headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.httpClient.post(completeUrl, params, { headers: headers, responseType: 'text', withCredentials: true })
            .pipe(
        //map(response => new NmUser(response)),
        catchError(this.appService.handleError))
            .subscribe(function (response) {
            //response = response.replace(/[()]/g, '');
            response = response.substring(1, response.length - 1);
            response = JSON.parse(response);
            console.log(response);
            if (response.statusCode > -1) {
                console.log(response);
                _this.accountNumberChange[tab.name].next(response.tableRows);
                //
            }
            else {
                _this.appService.showMessage('Error', response.statusText);
            }
            _this.appService.stopSpin2();
            // this.appService.stopSpin();
        }, function (error) {
            _this.appService.showMessage('Error', error.statusText);
            //  this.appService.stopSpin();
            _this.appService.stopSpin2();
        });
    };
    //updateCardabelRec(tab, cardabel_rec, matchingAction, records)
    MatchingService.prototype.updateCardabelRec = function (tab, records, checkedIndexes, reconciliationComment) {
        var _this = this;
        var subtabletype = tab.matchingType;
        if (tab.matchingType === "Test") {
            subtabletype = "predicted";
        }
        this.appService.startSpin2();
        // this.appService.startSpin();
        var url = this.url;
        var webservice = "AnalysisMatchingUpdateCardabelRec";
        var completeUrl = url + webservice;
        var matchingRecords = JSON.stringify(records);
        //create new http params
        var params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", tab.workflow)
            .set("segAttrValue", tab.segAttributeValue)
            .set("subTableName", tab.subtable)
            .set("subTableType", subtabletype)
            .set("segregation", tab.accountNumber)
            //.set("matchingAction", matchingAction)
            //.set("fieldID", fieldID)
            //.set("cardabel_rec", cardabel_rec)
            .set("matchingRecords", matchingRecords)
            .set("reconciliationComment", reconciliationComment);
        var headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.httpClient.post(completeUrl, params, { headers: headers, responseType: 'text', withCredentials: true })
            .pipe(
        //map(response => new NmUser(response)),
        catchError(this.appService.handleError))
            .subscribe(function (response) {
            //response = response.replace(/[()]/g, '');
            response = response.substring(1, response.length - 1);
            response = JSON.parse(response);
            console.log(response);
            if (response.statusCode > -1) {
                console.log(response);
                if (response.tableRows.length > 0) {
                    var _loop_1 = function () {
                        var new_cardabel_rec = +response.tableRows[+m].new_cardabel_rec;
                        var trueStatus = response.tableRows[+m].true_status;
                        var trueStatusType = response.tableRows[+m].true_status_type;
                        var transactions = response.tableRows[+m].transactions;
                        for (var n in transactions) {
                            var index = _this.gridData[tab.name].findIndex(function (item) { return item[_this.fieldID[tab.name]] === transactions[n]; });
                            _this.gridData[tab.name][index]["cardabel_rec"] = new_cardabel_rec;
                            _this.gridData[tab.name][index]["TrueStatus"] = trueStatus;
                            _this.gridData[tab.name][index]["TrueStatusType"] = trueStatusType;
                        }
                    };
                    for (var m in response.tableRows) {
                        _loop_1();
                    }
                    _this.gridDataChange[tab.name].next(_this.gridData[tab.name]);
                }
                //
            }
            else {
                _this.gridDataChange[tab.name].next(_this.gridData[tab.name]);
                _this.appService.showMessage('Error', response.statusText);
            }
            _this.appService.stopSpin2();
            // this.appService.stopSpin();
        }, function (error) {
            _this.appService.showMessage('Error', error.statusText);
            //  this.appService.stopSpin();
            _this.appService.stopSpin2();
        });
    };
    //sets the field id
    MatchingService.prototype.setFieldID = function (table, tab) {
        this.fieldID[tab] = table.TableDescription.IDField;
        this.fieldIDChange[tab].next(this.fieldID[tab]);
    };
    //returns the field id
    MatchingService.prototype.getFieldID = function (tab) {
        return this.fieldID[tab];
    };
    MatchingService.prototype.setVarianceThreshold = function (tab, varianceThreshold) {
        this.varianceThreshold[tab] = varianceThreshold;
        this.varianceThresholdChange[tab].next(this.varianceThreshold[tab]);
    };
    MatchingService.prototype.getVarianceThreshold = function (tab) {
        return this.varianceThreshold[tab];
    };
    MatchingService.prototype.setShowMenu = function (showMenu, tab) {
        this.showMenu[tab] = showMenu;
    };
    MatchingService.prototype.getShowMenu = function (tab) {
        return this.showMenu[tab];
    };
    return MatchingService;
}());
export { MatchingService };
