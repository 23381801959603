/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./topicstart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./topicstart.component";
import * as i3 from "../../../../shared/shared-common-initialization.service";
var styles_TopicstartComponent = [i0.styles];
var RenderType_TopicstartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopicstartComponent, data: {} });
export { RenderType_TopicstartComponent as RenderType_TopicstartComponent };
export function View_TopicstartComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "col-xs-12 col-md-12 col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "pi pi-question-circle piHelp"], ["id", "helpIcon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getHelpDocumentation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_TopicstartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-topicstart", [], null, null, null, View_TopicstartComponent_0, RenderType_TopicstartComponent)), i1.ɵdid(1, 114688, null, 0, i2.TopicstartComponent, [i3.SharedInitializationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TopicstartComponentNgFactory = i1.ɵccf("app-topicstart", i2.TopicstartComponent, View_TopicstartComponent_Host_0, {}, {}, []);
export { TopicstartComponentNgFactory as TopicstartComponentNgFactory };
