/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./errors-component.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./errors-component.component";
var styles_ErrorsComponent = [i0.styles];
var RenderType_ErrorsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorsComponent, data: {} });
export { RenderType_ErrorsComponent as RenderType_ErrorsComponent };
function View_ErrorsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["ERROR ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "a", [["mat-raised-button", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["Go to ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = ("/" + ((_co.routeParams == null) ? null : _co.routeParams.fromTab)); _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.routeParams == null) ? null : _co.routeParams.error); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 4).target; var currVal_2 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_4 = ((_co.routeParams == null) ? null : _co.routeParams.fromTab); _ck(_v, 6, 0, currVal_4); }); }
export function View_ErrorsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "error-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorsComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.routeParams == null) ? null : _co.routeParams.error); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ErrorsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-errors-component", [], null, null, null, View_ErrorsComponent_0, RenderType_ErrorsComponent)), i1.ɵdid(1, 114688, null, 0, i4.ErrorsComponent, [i2.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorsComponentNgFactory = i1.ɵccf("app-errors-component", i4.ErrorsComponent, View_ErrorsComponent_Host_0, {}, {}, []);
export { ErrorsComponentNgFactory as ErrorsComponentNgFactory };
