import {  Injectable, ViewRef } from '@angular/core';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';


@Injectable()
export class DashboardEditingChartService {
    constructor() { }


    //this is to add a new graph
    addNewGraph(vis) {
        let [topPosition, leftPosition] = vis.dashboardChartDimensionsService.getTopLeftPositions(vis);
        let width = 300 / vis.windowWidth;
        let height = 300 / vis.windowHeight;
        vis.columns.push({
            dimension: '',
            xHeader: '',
            yHeader: '',
            type: '',
            aggMethod: 'count',
            aggMethodCombo: 'count',
            aggregatedDimensionMethod: '',
            numericHeader: '',
            numericDecimal: 0,
            dateHeader: '',
            dateBucketing: { name: '' },
            dateFormat: { name: 'DD-MM-YYYY' },
            datePeriod: { name: "Days" },
            datePeriodValue: 20,
            datePeriodFixedDate: new Date(),
            top: 3,
            activeResize: false,
            displayOther: true,
            width: width,
            height: height,
            dragPosition: { x: topPosition, y: leftPosition },
            topPosition: topPosition,
            leftPosition: leftPosition,
            rightPosition: leftPosition + width,
            bottomPosition: topPosition + height,
            colorScheme: ['#0daaba', '#e66465', '#f6b73c'],
            commentBox: "",
            xAxisLabel: "",
            yAxisLabel: "",
            title: "",
            tooltip: "",
            fontSize: 14,
            margin: { top: 10, bottom: 20, right: 20, left: 70, changed: false },
            xAxis: { minimum: undefined, maximum: undefined, changed: false },
            yAxis: { minimum: undefined, maximum: undefined, changed: false },
            y1Axis: { minimum: undefined, maximum: undefined, changed: false },
        })

        vis.dashboardService.initChartChanges(vis.mainTab, vis.tabAlias, vis.columns);
    }

    //this is to delete a graph
    deleteGraph(i, vis) {
        let columns = [...vis.columns];
        columns.splice(i, 1);
        vis.columns = [];

        if (vis.cd && !(vis.cd as ViewRef).destroyed) {
            vis.cd.detectChanges();
          }
        setTimeout(() => {
            vis.columns = [...columns]
            vis.dashboardService.setChartChanges(vis.mainTab, vis.tabAlias, vis.columns);
        }, 10)
      
    }

    editGraph(vis) {
        vis.columns[vis.index] = JSON.parse(JSON.stringify(vis.editColumn));
        vis.index = 0;
        vis.editColumn = {};
        vis.columns = vis.dashboardChartDimensionsService.resetColumnDimensions(vis.columns, vis);
        if (vis.cd && !(vis.cd as ViewRef).destroyed) {
            vis.cd.detectChanges();
        }
    }

    displayEditGraph(vis, i) {
        vis.index = +i;
        vis.editGraphSideBar = true;
        vis.disabledTypeOptions = [];
        let mainTab = vis.mainTab + "2";
        vis.header = [{ name: '' }]
        vis.xHeader = [{ name: '' }];
        vis.yHeader = [{ name: '' }];
        vis.barHeader = [{ name: '' }];
        vis.comboHeader = [{ name: '' }];
        let resetEvent = { target: { value: "" } };
        vis.filterHeaders(resetEvent, "numericHeaders");
        vis.filterHeaders(resetEvent, "Headers");
        vis.dashboardService.setChartChange(mainTab, vis.tabAlias, vis.columns, 100, i);
        vis.editColumn = vis.columns[i];

        vis.numericHeaders = vis.dashboardInitTypesScalesService.getNumericHeaders(vis);
        // this.header.setValue('');
        //this.type.setValue('');

        let featuresArray = [
            { name: 'dimension', options: vis.headers },
            { name: 'type', options: vis.types },
            { name: 'aggMethod', options: vis.aggMethods },
            { name: 'aggregatedDimensionMethod', options: vis.aggregatedDimensionMethods },
            { name: 'numericHeader', options: vis.numericHeaders },
            { name: 'comboHeader', options: vis.numericHeaders },
            { name: 'dateBucketing', options: vis.dateBucketingOptions },
            { name: 'dateFormat', options: vis.dateFormatOptions },
            { name: 'datePeriod', options: vis.datePeriodOptions },
            { name: 'xHeader', options: vis.headers },
            { name: 'yHeader', options: vis.headers },
        ]
        vis.editColumn.datePeriodFixedDate = new Date(vis.editColumn.datePeriodFixedDate);
        for (var k in featuresArray) {
            this.setValues(featuresArray[k].name, featuresArray[k].options, vis)
        }

        if (vis.editColumn.type === "line") {
            vis.dashboardInitTypesScalesService.isDimensionDate(vis.editColumn.dimension, vis.filteredData, vis)
        }

    }

    setValues(name, options, vis) {
        if (vis.editColumn[name] != "") {
            for (var s in options) {
                if (vis.editColumn[name] === options[s].name) {
                    console.log(this[name]);
                    if (name != "dimension") {
                        vis[name] = [options[s]]
                    }
                    else {
                        vis.header = [options[s]]
                    }

                    if (name === "aggMethod") {
                        vis.changeAggMethod(options[s].name, '')
                    }
                    break;
                }
            }
        }
        else {
            //  this[name].setValue("");
        }
    }

    changeType(vis) {
        if (vis.editColumn.type === "line") {
            vis.editColumn.top = 50;
        }
        else {
            vis.editColumn.top = 3;
        }
    }
    //this is to set the color for the color scheme
    setColor(i, color, vis) {
        let colorScheme = [];
        for (var j in vis.editColumn.colorScheme) {
            if (+i != +j) {
                colorScheme.push(vis.editColumn.colorScheme[j])
            }
            else {
                colorScheme.push(color)
            }
        }
        vis.editColumn.colorScheme = colorScheme;
        vis.changeColumn(100);
    }

    drop(event: CdkDragDrop<any[]>, param, vis) {
     
        if (event.previousContainer === event.container) {
            // moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {

            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex,
            );

            if ((event.container.element.nativeElement.getAttribute('class') && event.container.element.nativeElement.getAttribute('class').indexOf('header-list') !== -1)) {
                for (let i = 0; i < 2; i++) {
                    if (event.container.data[i]) {
                        if ((event.container.data[i].name != "" || event.container.data[i].name === "") && i === 1) {
                            if (event.container.data[i].name != "") {
                                event.previousContainer.data.push(event.container.data[1])
                            }

                            event.container.data.splice(1, 1);
                        }
                    }


                }

                if (param != '') {
                    //this[param] = 
                    vis.editColumn[param] = event.container.data[0].name;
                }
            }
            else if (event.previousContainer.data.length === 0) {
                event.previousContainer.data.push({ name: "" });
                if (param === "") {
                    let possibilities = ["dimension", "xHeader", "yHeader"];
                    for (var i in possibilities) {
                        if (vis.editColumn[possibilities[i]] === event.container.data[event.currentIndex].name) {
                            vis.editColumn[possibilities[i]] = "";
                        }
                    }
                }

            }
            else {
                if (param === "") {
                    let possibilities = ["dimension", "xHeader", "yHeader"];
                    for (var i in possibilities) {
                        if (vis.editColumn[possibilities[i]] === event.container.data[event.currentIndex].name) {
                            vis.editColumn[possibilities[i]] = "";
                        }
                    }
                }
                else {
                    vis.editColumn[param] = "";
                }
            }
        }


        if (vis.editColumn.type === "line") {
            vis.dashboardInitTypesScalesService.isDimensionDate(vis.editColumn.dimension, vis.filteredData, vis)
        }
        vis.changeColumn(100);

        if (vis.cd && !(vis.cd as ViewRef).destroyed) {
            vis.cd.detectChanges();
        }
    }


}