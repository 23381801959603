import { Component, OnInit, NgModule, ViewEncapsulation, Input, SimpleChanges, ElementRef, ChangeDetectionStrategy, Output, ViewChild, ChangeDetectorRef, forwardRef, ViewRef } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule, FormsModule, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatFormField, MatFormFieldModule, MatFormFieldControl } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule, MatList, MatSelectionList } from '@angular/material/list';
import { AppModule } from 'src/app/app.module';
import { MatInputModule, MatSelectModule, MatRipple, MatIconModule, TooltipPosition, MatMenuModule, MatMenuTrigger, MatCheckboxModule } from '@angular/material';
import { EventEmitter } from '@angular/core';
import { ContextMenu } from 'primeng/contextmenu';
import { SharedGeneralFunctionsService } from '../shared-general-functions.service';
import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { HeroFilterPipe } from '../filter.pipe';
import { CardabelListboxItemComponent } from './cardabel-listbox-item/cardabel-listbox-item.component';
import { Sidebar } from 'primeng/sidebar';
import { CardabelTooltipDirective, CardabelTooltipModule } from '../cardabel-tooltip/cardabel-tooltip.directive';
import { CardabelTooltipComponent } from '../cardabel-tooltip/cardabel-tooltip.component';


@Component({
  selector: 'app-cardabel-listbox',
  templateUrl: './cardabel-listbox.component.html',
  styleUrls: ['./cardabel-listbox.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CardabelListboxComponent),
    multi: true
  }],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardabelListboxComponent implements ControlValueAccessor, OnInit {

  //init inputs
  @Input() title: string;
  @Input() options: any[];
  @Input() filterBy: string;
  @Input() multiple: boolean;
  @Input() placeholder: string;
  @Input() tooltipPosition: string;
  @Input() tooltipDisabled: boolean;
  @Input() tooltipText: any;
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Input() disabledOption: boolean = false;
  @Input() formControl: FormControl;
  @Input() menuOpened: boolean = true;
  @Input() sortArray: any[] = [];

  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

  sortedBySelectedRows: boolean = false;

  filteredOptions: any[] = [];
  value;
  onChange;
  tooltip: any;
  pendingTooltip: any;

  myControl = new FormControl('');


  allOptionsSelected: boolean = false;

  //Tooltip
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  position = new FormControl(this.positionOptions[0]);
  disabled = new FormControl(false);
  allChecked = new FormControl(false);

  selectedRows: any = {};
  name1: string = "";
  name2: string = "";

  strong: any = {};

  constructor(private cd: ChangeDetectorRef,
    private sharedFunctionsService: SharedGeneralFunctionsService) { }
  ngOnInit() {
    console.log("cardabel listbox")
    // console.log(this.options)

    if (this.sortArray.length != 0) {
      this.sortOptionsFromOtherArray()
    }
    //when the formControl is changed then set selected rows and filtered options
    if (this.formControl) {
      this.formControl.valueChanges.subscribe((value: any) => {
        this.setFormControlValue();
      });
    }

    //when filtering in mat form field
    this.myControl.valueChanges
      .subscribe(
        (value: string) => {
          this.filterOptions()
        }
      )


  }

  sortOptionsFromOtherArray() {
    this.sortArray.sort(function (a, b) {
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      }
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }
      return 0;
    });
    console.log(this.sortArray);
    var name = this.filterBy;
    let sortArray = [...this.sortArray]

    console.log(sortArray);
    this.filteredOptions.sort(function (a, b) {
      if (sortArray.indexOf(a[name]) === -1) {
        return 1
      }
      else if (sortArray.indexOf(b[name]) === -1) {
        return -1
      }
      else {
        // console.log(a[name]);
        // console.log(b[name])
        //  console.log(sortArray.indexOf(a[name]) - sortArray.indexOf(b[name]))
        return sortArray.indexOf(a[name]) - sortArray.indexOf(b[name]);
      }

    });
    let filteredOptions = [...this.filteredOptions]
    console.log(filteredOptions)

    for (var k in this.filteredOptions) {
      this.strong[this.filteredOptions[k][name]] = false;
    }
    for (var n in this.sortArray) {
      this.strong[this.sortArray[n]] = true;
    }
    if (this.cd && !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
  }

  trackByIdx(index, val) {
    return index;
  }

  filterOptions() {
    if (this.myControl.value) {
      const filterValue = this.myControl.value.toLowerCase();

      if ((this.name1 != "") && (this.name2 != "")) {
        this.filteredOptions = this.options.filter(item =>
          item[this.name1][this.name2].toString().toLowerCase().includes(filterValue))
      }
      else {
        this.filteredOptions = this.options.filter(item =>
          item[this.filterBy].toString().toLowerCase().includes(filterValue))
      }

      if (this.formControl.value.length === this.filteredOptions.length) {
        this.allOptionsSelected = true;
        this.allChecked.setValue(true);
      }
      else {
        this.allOptionsSelected = false;
        this.allChecked.setValue(false);
      }
    }
    else {
      this.filteredOptions = [...this.options];
    }

    this.sortOptionsFromOtherArray();
  }
  //this allows to check (uncheck) all options when the all checkbox is clicked or unclicked 
  selectAllOptions() {
    this.allOptionsSelected = !this.allOptionsSelected;
    let selectedRows = [];

    if ((this.name1 != "") && (this.name2 != "")) {
      if (this.allOptionsSelected === true) {
        for (var i in this.filteredOptions) {
          this.selectedRows[this.filteredOptions[i][this.name1][this.name2]] = true;

        }
        selectedRows = this.filteredOptions;
      }
      else {
        for (var i in this.filteredOptions) {
          this.selectedRows[this.filteredOptions[i][this.name1][this.name2]] = false;
        }

      }
    }
    else {
      if (this.allOptionsSelected === true) {
        for (var i in this.filteredOptions) {
          this.selectedRows[this.filteredOptions[i][this.filterBy]] = true;
        }
        selectedRows = this.filteredOptions;
      }
      else {
        for (var i in this.filteredOptions) {
          this.selectedRows[this.filteredOptions[i][this.filterBy]] = false;
        }

      }
    }

    //multiple
    this.onChange(selectedRows);
    this.change.emit({ value: selectedRows })

    // console.log(this.selectAllOptions)
  }


  //when a checkbox is clicked, the selected rows must be updated and the form control in the parent component too
  selectionChange(option) {
    // console.log(option)
    let selectedRows = [];
    selectedRows = this.getSelectedRows(option, true);

    if (this.multiple === true) {


      //multiple
      this.onChange(selectedRows);
      this.change.emit({ value: selectedRows });
      // this.virtualScroll.scrollToIndex(0);

    }
    else {



      if (selectedRows.length != 0) {
        if (this.virtualScroll) {
          this.virtualScroll.scrollToIndex(0);

        }
        this.onChange(selectedRows[0]);
        this.change.emit({ value: selectedRows[0] });

      }
      else {
        this.onChange("");
        this.change.emit({ value: "" });
        if (this.virtualScroll) {
          this.virtualScroll.scrollToIndex(0);

        }
      }

    }

    //this.sortSelectedRows(selectedRows);

    //console.log(selectedRows);

  }

  getSelectedRows(option, changeParam) {
    let selectedRows = [];
    if (this.multiple === true) {
      if ((this.name1 != "") && (this.name2 != "")) {
        for (var i in this.options) {
          if (this.options[i][this.name1][this.name2] === option) {
            if (changeParam) {
              this.selectedRows[this.options[i][this.name1][this.name2]] = !this.selectedRows[this.options[i][this.name1][this.name2]];
            }

          }

          if (this.selectedRows[this.options[i][this.name1][this.name2]] === true) {
            selectedRows.push(this.options[i])
          }

        }
      }
      else {
        for (var i in this.options) {
          if (this.options[i][this.filterBy] === option) {
            if (changeParam) {
              this.selectedRows[this.options[i][this.filterBy]] = !this.selectedRows[this.options[i][this.filterBy]];
            }
          }

          if (this.selectedRows[this.options[i][this.filterBy]] === true) {
            selectedRows.push(this.options[i])
          }

        }
      }
    }
    else {
      if ((this.name1 != "") && (this.name2 != "")) {
        for (var i in this.options) {
          if (this.options[i][this.name1][this.name2] === option) {
            if (changeParam) {
              this.selectedRows[this.options[i][this.name1][this.name2]] = !this.selectedRows[this.options[i][this.name1][this.name2]];
            }
          }
          else if (changeParam) {
            this.selectedRows[this.options[i][this.name1][this.name2]] = false;
          }
          if (this.selectedRows[this.options[i][this.name1][this.name2]] === true) {
            selectedRows.push(this.options[i]);

          }
        }
      }
      else {
        for (var i in this.options) {
          if (this.options[i][this.filterBy] === option) {
            if (changeParam) {
              this.selectedRows[this.options[i][this.filterBy]] = !this.selectedRows[this.options[i][this.filterBy]];
            }
          }
          else if (changeParam) {
            this.selectedRows[this.options[i][this.filterBy]] = false;
          }

          if (this.selectedRows[this.options[i][this.filterBy]] === true) {
            selectedRows.push(this.options[i]);

          }
        }



      }
    }
    return selectedRows;
  }

  onSortBySelectedRows() {
    this.sortedBySelectedRows = !this.sortedBySelectedRows;

    if (this.sortedBySelectedRows) {
      let selectedRows = this.getSelectedRows("", false);
      this.sortSelectedRows(selectedRows);
    }
    else {
      this.sortOptions();
    }

  }
  sortSelectedRows(selectedRows) {
    // this.filteredOptions = this.filteredOptions.sort((a, b) => a.city.localeCompare(b.city) || b.price - a.price);
    var name = this.filterBy;
    selectedRows.sort(function (a, b) {
      if (a[name]) {
        var key1 = a[name].toString().toLowerCase(), key2 = b[name].toString().toLowerCase();
        if (key1 < key2) //sort string ascending
          return -1;
        if (key1 > key1)
          return 1;
        return 0; //default return value (no sorting)
      }
      else {
        return 0;
      }
    });

    let selectedRowsOrder = selectedRows.map(item => item[name].toString().toLowerCase());

    console.log(selectedRowsOrder);

    console.log(this.selectedRows)
    this.filteredOptions.sort(function (a, b) {
      if (a[name]) {
        var key1 = a[name].toString().toLowerCase(), key2 = b[name].toString().toLowerCase();

        if (selectedRowsOrder.indexOf(key1) > -1) {
          if (selectedRowsOrder.indexOf(key2) > -1) {
            return selectedRowsOrder.indexOf(key1) - selectedRowsOrder.indexOf(key2);
          }
          else {
            return -1;
          }
        }
        else if (selectedRowsOrder.indexOf(key2) > -1) {
          return 1;
        }
        else {
          return key1 - key2;
        }
      }
      return 0;
    })
  }

  //when changes are made in parent component (filtered Options for example)
  ngOnChanges(changes: SimpleChanges) {

    if (changes.filterBy) {
      if (this.filterBy.indexOf('.') > -1) {
        this.name1 = this.filterBy.substring(0, this.filterBy.indexOf('.'));
        this.name2 = this.filterBy.substring(this.filterBy.indexOf('.') + 1, this.filterBy.length);
        // alert("name1!!")
        console.log(this.options)
        console.log(this.name1);
        console.log(this.name2);
      }
    }

    if (changes.options) {

      setTimeout(() => {
        if (changes.options.currentValue) {
          this.options = [...changes.options.currentValue];
          if (this.options) {
            this.sortOptions();
          }
        }
      }, 100)

      //console.log(this.formControl.value)

    }

    //  console.log(this.options)

    if (changes.tooltipPosition) {
      for (var i in this.positionOptions) {
        if (changes.tooltipPosition.currentValue === this.positionOptions[i]) {
          this.position.setValue(this.positionOptions[i]);
        }
      }
    }

    if (changes.tooltipDisabled) {
      this.disabled.setValue(changes.tooltipDisabled.currentValue)
    }

    this.sortOptionsFromOtherArray();

    //console.log(this.tooltipText)

    // this.tooltip = "{{tooltipText[1]}}: {{option[tooltipText[1]]}}"


  }

  sortOptions() {

    if ((this.name1 != "") && (this.name2 != "")) {
      let name1 = this.name1;
      let name2 = this.name2;
      this.options.sort(function (a, b) {
        if (a[name1]) {
          if (a[name1][name2]) {
            var key1 = a[name1][name2].toString().toLowerCase(), key2 = b[name1][name2].toString().toLowerCase();
            if (key1 < key2) //sort string ascending
              return -1;
            if (key1 > key1)
              return 1;
            return 0; //default return value (no sorting)
          }
          else {
            return 0;
          }
        }
        else {
          return 0;
        }

      });
      this.filteredOptions = [...this.options];
      for (var i in this.options) {
        this.selectedRows[this.options[i][this.name1][this.name2]] = false;
      }
    }
    else {
      var name = this.filterBy;
      this.options.sort(function (a, b) {
        if (a[name]) {
          var key1 = a[name].toString().toLowerCase(), key2 = b[name].toString().toLowerCase();
          if (key1 < key2) //sort string ascending
            return -1;
          if (key1 > key1)
            return 1;
          return 0; //default return value (no sorting)
        }
        else {
          return 0;
        }

      });

      this.filteredOptions = [...this.options];
      for (var i in this.options) {
        this.selectedRows[this.options[i][this.filterBy]] = false;
      }
    }
    this.filterOptions();
    this.setFormControlValue()


  }

  //set form control and selected rows value to check uncheck 
  setFormControlValue() {
    if (this.formControl.value === "") {
      if ((this.name1 != "") && (this.name2 != "")) {

        for (var i in this.options) {
          this.selectedRows[this.options[i][this.name1][this.name2]] = false;

        }
      }
      else {
        for (var i in this.options) {
          this.selectedRows[this.options[i][this.filterBy]] = false;

        }
      }

    }
    else {

      if ((this.name1 != "") && (this.name2 != "")) {
        for (var i in this.options) {
          this.selectedRows[this.options[i][this.name1][this.name2]] = false;
        }
      }
      else {
        for (var i in this.options) {
          this.selectedRows[this.options[i][this.filterBy]] = false;
        }
      }

      if ((this.formControl.value) && this.formControl.value != "") {
        if (this.formControl.value.length === this.filteredOptions.length) {
          this.allOptionsSelected = true;
          this.allChecked.setValue(true);
        }
        else {
          this.allOptionsSelected = false;
          this.allChecked.setValue(false);
        }

        if (this.multiple === false) {
          if ((this.name1 != "") && (this.name2 != "")) {
            for (var k in this.options) {
              if (this.options[k][this.name1][this.name2] === this.formControl.value[this.name1][this.name2]) {
                this.selectedRows[this.options[k][this.name1][this.name2]] = true;
                break;
              }
            }
          }
          else {
            for (var k in this.options) {
              if (this.options[k][this.filterBy] === this.formControl.value[this.filterBy]) {
                this.selectedRows[this.options[k][this.filterBy]] = true;
                break;
              }
            }
          }

        }
        else {

          if ((this.name1 != "") && (this.name2 != "")) {
            for (var k in this.options) {
              for (var j in this.formControl.value) {
                if (this.options[k][this.name1][this.name2] === this.formControl.value[j][this.name1][this.name2]) {
                  this.selectedRows[this.options[k][this.name1][this.name2]] = true;
                  break;
                }
              }

            }
          }
          else {
            for (var k in this.options) {
              for (var j in this.formControl.value) {
                if (this.options[k][this.filterBy] === this.formControl.value[j][this.filterBy]) {
                  this.selectedRows[this.options[k][this.filterBy]] = true;
                  break;
                }
              }

            }
          }

        }
      }
    }

    //console.log(this.selectedRows)

    if (this.cd && !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }

    if (this.formControl.value) {
      if ((this.formControl.value.length) && (this.formControl.value.length != 0)) {
        let selectedRows = this.getSelectedRows("", false);
        // this.sortSelectedRows(selectedRows);
        if (this.cd && !(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }
      }

    }

  }

  public scrollTo(event) {
    if (this.virtualScroll) {
      this.virtualScroll.scrollToIndex(0);

    }
    if (this.cd && !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
  }
  //control value accessor so that formcontrol works
  writeValue(value) {
    this.value = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
  }

}

@NgModule({
  imports: [
    CommonModule, MatCheckboxModule, ScrollingModule, ReactiveFormsModule, MatMenuModule, MatIconModule, MatFormFieldModule, MatListModule, MatSelectModule, MatRadioModule, FormsModule, MatInputModule, CardabelTooltipModule

  ],
  entryComponents: [CardabelTooltipComponent],
  exports: [RouterModule, MatCheckboxModule, ScrollingModule, MatMenuModule, CardabelListboxItemComponent, CardabelListboxComponent, MatIconModule, MatFormFieldModule, MatListModule, MatSelectModule, MatRadioModule, FormsModule, MatInputModule, CardabelTooltipModule],
  declarations: [CardabelListboxComponent, CardabelListboxItemComponent, HeroFilterPipe],
  providers: [
    HeroFilterPipe,

  ]
})
export class CardabelListBoxModule {

}
