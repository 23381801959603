/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-start.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./admin-start.component";
import * as i3 from "../../shared/shared-common-initialization.service";
var styles_AdminStartComponent = [i0.styles];
var RenderType_AdminStartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminStartComponent, data: {} });
export { RenderType_AdminStartComponent as RenderType_AdminStartComponent };
export function View_AdminStartComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "offset-md-11 col-md-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "pi pi-question-circle piHelp"], ["id", "helpIcon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getHelpDocumentation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_AdminStartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-admin-start", [], null, null, null, View_AdminStartComponent_0, RenderType_AdminStartComponent)), i1.ɵdid(1, 114688, null, 0, i2.AdminStartComponent, [i3.SharedInitializationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminStartComponentNgFactory = i1.ɵccf("app-admin-start", i2.AdminStartComponent, View_AdminStartComponent_Host_0, {}, {}, []);
export { AdminStartComponentNgFactory as AdminStartComponentNgFactory };
