import { Injectable, ViewRef } from '@angular/core';
import { AppService } from '../../../app.service';
import * as d3 from 'd3';
import { DashboardService } from '../../dashboard.service';

@Injectable()
export class DashboardFilterChartService {

    constructor(
        private appService: AppService,
        private dashboardService: DashboardService,
       
    ) { }

    /* FILTERING ALL THE DASHBOARD */
    //this allows to format the filters (by field, value and type )and then calls the filter function
    formatAllFilters(event, vis) {

        vis.computeFilterWidth();
        for (var m in event.value) {
            let filterField = event.value[m].header;
            let filterValue = event.value[m].filter;
            let inputType = event.value[m].type;
            let filterType = event.value[m].filterType;
            let label = event.value[m].label;

            [vis.filters, vis.fieldExists] = this.returnFormattedFilters(vis.filters, vis.fieldExists, filterField, filterValue, inputType, filterType, label)

        }
        this.filter(vis);
    }
    //this allows to filter the data and update all the graphs according to the given filters
    filter(vis) {
        let filterSliceStream = vis.data;
        filterSliceStream = this.filterDataStream(vis, filterSliceStream);
        vis.filteredData = JSON.parse(JSON.stringify(filterSliceStream));
        this.appService.stopSpin2();
        if (vis.cd && !(vis.cd as ViewRef).destroyed) {
            vis.cd.detectChanges();
        }
        this.dashboardService.setChartChanges(vis.mainTab, vis.tabAlias, vis.columns)
    }

    //remove All Filters
    removeFilters(vis) {
        if (vis.cd && !(vis.cd as ViewRef).destroyed) {
            vis.cd.detectChanges();
        }

        setTimeout(() => {
            vis.filters = [];
            if (vis.cd && !(vis.cd as ViewRef).destroyed) {
                vis.cd.detectChanges();
            }
            this.filter(vis);
        }, 10)

    }

    //this allows to remove a specific filter and keep the last drill down value chart
    removeFilter(filter, vis) {
        this.appService.startSpin2();
        if (vis.cd && !(vis.cd as ViewRef).destroyed) {
            vis.cd.detectChanges();
        }

        setTimeout(() => {
            if (typeof filter === "string") {
                let number = vis.filters.length - 1;
                vis.filters.splice(number, 1);
                this.filter(vis)
            }
            else {
                for (var i in vis.filters) {
                    if (vis.filters[i].field === filter.field) {
                        vis.filters.splice(+i)
                    }
                }
                this.filter(vis)
            }
        }, 10)

    }

    /* FILTERING IN CHART & FROM CHART */

    resetFiltersArray(vis, type) {
        if (type === "bar") {
            vis.filtersInArray = [];
            vis.aggregFiltersInArray = [];
        }
        else if (type === "bubble") {
            for (var k in vis.keys) {
                let key = vis.keys[k];
                vis.filtersInArray[key] = [];
                vis.aggregFiltersInArray[key] = [];
            }
        }

        let aggMethod = vis.aggMethod;

        if (vis.aggregatedDimensionMethod === "count") {
            aggMethod = "count";
        }

        return [vis, aggMethod];
    }

    filterIn(filters, header, filtersInArray, newHeader, vis) {

        if (vis.type === "bubble") {
            filters = [];

            for (var k in vis.keys) {
                let key = vis.keys[k];
                if (filtersInArray[key].length != 0) {

                    if (newHeader != "") {
                        header = newHeader;
                    }

                    if (key != "Categorie") {
                        header = vis[key];
                    }
                    filters.push({ header: header, filter: filtersInArray[key], type: "", filterType: 'in', label: filtersInArray[key] })



                }
            }
        }
        if (vis.type === "bar" || vis.type === "treemap" || vis.type === "line" || vis.type === "pie") {
            for (var k in filters) {
                filters.push({ header: filters[k].field, filter: filters[k].value, type: filters[k].inputType, filterType: 'contains', label: filters[k].label })
            }
            if ((newHeader != "") && (!newHeader)) {
                header = newHeader;
            }
            filters.push({ header: header, filter: filtersInArray, type: "", filterType: 'in', label: filtersInArray });
        }

        return filters;
    }

    setClickFilter(vis, mouseThis, d, index, event) {
        if (vis.tooltip) {
            vis.tooltip.style("display", "none");
        }
        if (vis.focus) {
            vis.focus.style("display", "none")
        }

        vis.displayContextMenu = false;

        let yValue;
        let lineValue;

        if (vis.type === "bubble") {
            var bubbleValue = d["Categorie"];

        }
        if (vis.type === "bar") {
            index = Math.floor((vis.height - mouseThis) / vis.y.step());
            yValue = vis.y.domain()[index];
        }

        if (vis.type === "treemap") {
            yValue = d.data.key;
        }
        let header = vis.dashboardChartService.getHeader(vis);
        if (vis.type === "line" && vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis)) {
            yValue = d["Categorie"];
            vis.focus.style('display', "none");

            if (yValue) {
                let lineData;
                let systems;
                let removeDates;
                [lineData, systems, removeDates] = this.dashboardService.formatDashboardData(vis.data, vis.header, "", "", vis.aggMethod, "col", vis.numericHeader, vis.dateBucketing, vis.numericDecimal, vis.datePeriod, vis.datePeriodValue, vis.datePeriodFixedDate, vis.mainTab);

                for (var i in lineData) {
                    let date = new Date(lineData[i][vis.xVariable])
                
                    if (yValue.toDateString() === date.toDateString()) {

                        lineValue = lineData[i][vis.xVariable]

                    }
                }
            }
        }
        else if (vis.type === "line" && !vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis)) {
            yValue = d[vis.xVariable]
        }
        if (vis.type === "combo") {
            yValue = d[vis.xVariable]
        }
        if (vis.type === "pie") {
            yValue = d.data[vis.yVariable]
        }

        let keys = ["Categorie", "xHeader", "yHeader"];


        if ((yValue === "Other") || (bubbleValue === "Other") || !vis.viewerMode) {

        }

        else if (event.shiftKey && vis.type != "combo") {
            if (vis.type != 'treemap') {
                d3.selectAll('#' + vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + index).attr("fill", "#012B4E");
            }
            else {
                d3.selectAll('#' + vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + index).style("background", "#012B4E");

            }

            if (vis.type === "line" && vis.dateheader != "") {
                vis = this.formatClickFilters(vis, vis.header, "", "shiftkey", lineValue, index, d)
            }
            else {
                vis = this.formatClickFilters(vis, vis.header, "", "shiftkey", yValue, index, d)
            }

        }
        else if (event.altKey && vis.view != 'sidebar') {
            [vis.xPosition, vis.yPosition, vis.filtervalue, vis.displayContextMenu, yValue] = vis.dashboardTooltipContextMenuService.setContextMenu(vis, window, vis.xPosition, vis.yPosition, vis.height, vis.x, vis.y, vis.dataFiltered, vis.xVariable, vis.yVariable, vis.filtervalue, vis.displayContextMenu, mouseThis, d, d3.event);
            if (vis.cd && !(vis.cd as ViewRef).destroyed) {
                vis.cd.detectChanges();
            }
            let element = document.getElementsByClassName("contextMenu") as HTMLCollectionOf<HTMLElement>;
            document.body.appendChild(element[0]);

            // vis.expandedChartEmit.emit({ value: true });
            if (vis.cd && !(vis.cd as ViewRef).destroyed) {
                vis.cd.detectChanges();

            }
        }
        else {
            let header = vis.dashboardChartService.getHeader(vis);
            if (vis.type === "bar" || vis.type === "combo" || vis.type === "pie" || vis.type === "line" && !vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis)) {
                if (yValue) {
                    for (var i in vis.dataFiltered) {
                        if (vis.dataFiltered[i]) {
                            let isEqual = false;
                            let filter = vis.yVariable;
                            if (vis.type === "combo" || vis.type === "line") {
                                filter = vis.xVariable;
                            }
                            if ((vis.type === "bar" || vis.type === "pie" || vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis)) && vis.dataFiltered[i][vis.yVariable] === yValue) {
                                isEqual = true;

                            }

                            if ((vis.type === "combo" || vis.type === "line") && vis.dataFiltered[i][vis.xVariable] === yValue) {
                                isEqual = true;
                            }

                            if (isEqual) {
                                let type = typeof (vis.dataFiltered[i][vis.yVariable]);
                                let header = vis.header;

                                if (vis.newHeader != "") {
                                    header = vis.newHeader;
                                }

                                vis = this.formatClickFilters(vis, header, vis.dataFiltered[i][filter], "nokey", yValue, index, d)
                                break;
                            }

                        }
                    }
                }
            }
            else if (vis.type === 'line' && vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis)) {

                let header = vis.header;

                if (vis.newHeader != "") {
                    header = vis.newHeader;
                }

                vis = this.formatClickFilters(vis, header, lineValue, "nokey", yValue, index, d)

            }
            else if (vis.type === "bubble") {
                for (var i in keys) {
                    let key = keys[i];
                    let value = d[key];
                    let type = typeof (d[key]);

                    let header = vis.header;

                    if (vis.newHeader != "") {
                        header = vis.newHeader;
                    }
                    if (key != "Categorie") {
                        header = vis[key];
                    }

                    vis = this.formatClickFilters(vis, header, value, "nokey", yValue, index, d)
                }

            }
            else if (vis.type === "treemap") {

                if (yValue) {
                    for (var i in vis.dataFiltered) {
                        if (vis.dataFiltered[i]) {
                            if (vis.dataFiltered[i][vis.yVariable].toString() === yValue.toString()) {
                                let type = typeof (vis.dataFiltered[i][vis.yVariable]);
                                let header = vis.header;

                                if (vis.newHeader != "") {
                                    header = vis.newHeader;
                                }

                                vis = this.formatClickFilters(vis, header, vis.dataFiltered[i][vis.yVariable], "nokey", yValue, index, d)
                            }
                        }
                    }
                }
            }

        }

        return vis;
    }

    formatClickFilters(vis, header, filter, type, yValue, index, d) {

        if (type === "nokey") {
            let filters = [];
            if (vis.type === "line" && vis.dateBucketing != "Days" && vis.dateBucketing != "Day") {
                for (var k in vis.filters) {
                    filters.push({ header: vis.filters[k].field, filter: vis.filters[k].value, type: vis.filters[k].inputType, filterType: 'contains', label: vis.filters[k].label })
                }
                let filterType = vis.dateBucketing;
                let label = vis.multiFormat(filter)
                filters.push({ header: header, filter: filter, type: type, filterType: filterType, label: label });
                vis.filter.emit({ value: filters })
            }
            else if ((vis.aggregatedDimensionMethod === "") || (vis.aggregatedDimensionMethod === "none") || (!vis.aggregatedDimensionMethod)) {
                for (var k in vis.filters) {
                    filters.push({ header: vis.filters[k].field, filter: vis.filters[k].value, type: vis.filters[k].inputType, filterType: 'contains', label: vis.filters[k].label })

                }

                filters.push({ header: header, filter: filter, type: type, filterType: 'contains', label: filter });
                vis.filter.emit({ value: filters })
            }
            else {
                vis.filtersInArray = this.getAggregClickFilters(header, yValue, vis.aggMethod, vis.aggregatedDimensionMethod, vis.data, vis.numericHeader, vis.dateBucketing, vis.numericDecimal, vis.datePeriod, vis.datePeriodValue, vis.datePeriodFixedDate, vis.mainTab, vis.yVariable, vis.dateHeader);
                vis.filterIn();
            }
        }
        else if (type === "shiftkey") {
            if (vis.type === "bar" || vis.type === "treemap" || vis.type === "line" || vis.type === "pie") {
                if (vis.aggregatedDimensionMethod === "count") {
                    if (vis.aggregFiltersInArray.indexOf(yValue) === -1) {
                        vis.aggregFiltersInArray.push(yValue)
                    }
                    else {
                        vis.aggregFiltersInArray.splice(vis.aggregFiltersInArray.indexOf(yValue, 1));
                        d3.selectAll('#' + vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + index).attr("fill", vis.color);
                    }

                    vis.filtersInArray = [];
                    for (var i in vis.aggregFiltersInArray) {
                        vis.filtersInArray = vis.filtersInArray.concat(this.getAggregClickFilters(vis.header, vis.aggregFiltersInArray[i], vis.aggMethod, vis.aggregatedDimensionMethod, vis.data, vis.numericHeader, vis.dateBucketing, vis.numericDecimal, vis.datePeriod, vis.datePeriodValue, vis.datePeriodFixedDate, vis.mainTab, vis.yVariable, vis.dateHeader));
                    }

                }
                else {
                    if (vis.filtersInArray.indexOf(yValue) === -1) {
                        vis.filtersInArray.push(yValue)
                    }
                    else {
                        vis.filtersInArray.splice(vis.filtersInArray.indexOf(yValue, 1));
                        d3.selectAll('#' + vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + index).attr("fill", vis.color);
                    }
                }
            }
            else if (vis.type === "bubble") {
                d3.selectAll('#' + vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + index).attr("fill", "#012B4E");
                let name = "";
                for (var t in vis.keys) {
                    let key = vis.keys[t];
                    let value = d[key];
                    name = name + value;

                }

                let keys = Object.keys(vis.shiftFilterValues);
                let itemExistsIndex = vis.shiftFilterValues.findIndex((item => item.id === name));

                if (itemExistsIndex > -1) {
                    vis.shiftFilterValues.splice(itemExistsIndex, 1);
                    d3.selectAll('#' + vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + index).attr("fill", vis.color);
                }
                else {
                    let newdata = {};
                    newdata['id'] = name;
                    for (var t in vis.keys) {
                        let key = vis.keys[t];
                        let value = d[key];
                        newdata[key] = value;
                    }
                    vis.shiftFilterValues.push(newdata)
                }
                vis.filtersInArray = {};

                for (var t in vis.keys) {
                    let key = vis.keys[t];
                    vis.filtersInArray[key] = [];
                    for (var k in vis.shiftFilterValues) {
                        vis.filtersInArray[key].push(vis.shiftFilterValues[k][key])
                    }
                    vis.filtersInArray[key] = vis.filtersInArray[key].filter((item, index) => vis.filtersInArray[key].indexOf(item) === index);

                }
            }
        }
        else if (type === "alt") {

        }


        return vis;
    }

    formatFilters(vis, header, newHeader, filters, filter, aggregatedDimensionMethod, fieldExists) {
        let filterField = header;
        if (newHeader != "") {
            filterField = newHeader;
        }

        let filterValue = filter.Categorie;
        let inputType = "contains";
        let filterType = "contains";
        let label = vis.dashboardAxisFormattingService.getTickFormat(filterValue, vis)

        if (aggregatedDimensionMethod === "count") {
            inputType = "in";
            filterType = "in";
            filterValue = this.getAggregClickFilters(filterField, filterValue, vis.aggMethod, vis.aggregatedDimensionMethod, vis.data, vis.numericHeader, vis.dateBucketing, vis.numericDecimal, vis.datePeriod, vis.datePeriodValue, vis.datePeriodFixedDate, vis.mainTab, vis.yVariable, vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis));

            aggregatedDimensionMethod = "";
        }

        if (vis.type === "line" && vis.dateBucketing != "Day" && vis.dateBucketing != "Days") {
            filterType = vis.dateBucketing;
        }

        [filters, fieldExists] = this.returnFormattedFilters(filters, fieldExists, filterField, filterValue, inputType, filterType, label)

        return [filters, newHeader, fieldExists]
    }

    formatChartFilterData(chartData, data, filters) {
        let filterSliceStream = data;

        var vis = this;
        filterSliceStream = this.filterDataStream(vis, filterSliceStream)

        chartData = JSON.parse(JSON.stringify(filterSliceStream));

        return chartData;
    }

    //this allows to return the filters when clicking on the aggregated value in a chart 
    getAggregClickFilters(header, yValue, aggMethod, aggregatedDimensionMethod, data, numericHeader, dateBucketing, numericDecimal, datePeriod, datePeriodValue, datePeriodFixedDate, mainTab, yVariable, dateHeader) {

        if (aggregatedDimensionMethod === "count") {
            aggMethod = "count";
        }
        let filteredData = this.dashboardService.formatDashboardData(data, header, "", "", aggMethod, "col", numericHeader, dateBucketing, numericDecimal, datePeriod, datePeriodValue, datePeriodFixedDate, mainTab);

        let filtersInArray = [];

        filteredData = filteredData.filter(item => {
            return item['col'] === yValue;
        })

        for (var i in filteredData) {
            let type = typeof (filteredData[i][yVariable]);

            filtersInArray.push(filteredData[i][yVariable]);
        }


        return filtersInArray;

    }

    
    /* SHARED FUNCTIONS BTW ALL THE DASHBOARD & CHARTS */

    //allows to filter data when a chart is clicked or when using the drill down
    filterDataStream(vis, filterSliceStream) {
        for (var i in vis.filters) {
            var value = vis.filters[i].value;
            var field = vis.filters[i].field;
            var type = vis.filters[i].inputType;
            var filterType = vis.filters[i].filterType;
            let minDate;
            let maxDate;
            if (filterType === "Months" || filterType == "Weeks" || filterType === "Years" || filterType === "Quarter") {
                let year = new Date(value).getFullYear();
                let month = new Date(value).getMonth();
                let day = new Date(value).getDay();

                if (filterType === "Months") {
                    minDate = new Date(year, month + 1, 1);
                    maxDate = new Date(year, month + 1, 0);
                }
                if (filterType === "Quarter") {
                    maxDate = new Date(year, month + 1, 0);
                    minDate = new Date(year, month - 2, 1);
                }
                if (filterType === "Years") {
                    minDate = new Date(year, 1, 1);
                    maxDate = new Date(year, 12, 0);
                }
            }

            filterSliceStream = filterSliceStream.filter(function (item) {
                if (item[field] === null) {
                    item[field] = " null";

                    if (field === "truestatus") {
                        item[field] = "Unknown"
                    }
                }
                else if (item[field] === "") {
                    item[field] = "Unknown"
                }

                if (value != undefined) {

                    if (filterType === "contains") {
                        let itemField = JSON.stringify(item[field]);
                        let valueField = JSON.stringify(value)
                        if (itemField) {
                            if (itemField.toLowerCase() != valueField.toLowerCase()) {
                                return false;
                            }

                        }
                        else {
                            return false;
                        }

                    }
                    else if (filterType === "in") {
                        for (var i in value) {
                            if (typeof item[field] === 'string') {
                                if (item[field].toLowerCase().indexOf(value[i].toString().toLowerCase()) > -1) {
                                    return true;
                                }
                            }
                            else if (typeof item[field] === 'number') {
                                if ((value[i] != 'null') && (value[i] != null)) {
                                    value[i] = +value[i];
                                }


                                if (item[field] === value[i]) {
                                    return true;
                                }
                            }
                            else if (item[field] === null) {
                                if (value[i] === "null") {
                                    value[i] = null;
                                }

                                if (item[field] === value[i]) {
                                    return true;
                                }
                            }

                        }
                        return false;
                    }
                    else if (filterType === "Months" || filterType == "Weeks" || filterType === "Years" || filterType === "Quarter") {

                        if (item[field] && item[field] != null) {
                            if (new Date(item[field]) <= maxDate && new Date(item[field]) >= minDate) {
                                return true;
                            }
                            else {
                                return false;
                            }

                        }
                        else {
                            return false;
                        }

                    }
                }

                return true;
            })


        };

        return filterSliceStream;
    }

    returnFormattedFilters(filters, fieldExists, filterField, filterValue, inputType, filterType, label) {
        fieldExists = false;
        for (var i in filters) {
            if (filters[i].field === filterField) {
                fieldExists = true;
                filters[i].value = filterValue;
                filters[i].inputType = inputType;
                filters[i].filterType = filterType;
                filters[i].label = label;
            }
        }
        if (fieldExists === false) {
            filters.push({ field: filterField, value: filterValue, inputType: inputType, filterType: filterType, label: label })
        }

        for (var k = filters.length - 1; k > -1; k--) {
            if (((filters[k].value === "") && (filters[k].value2 === "")) || filters[k].value === null || filters[k].value2 === null) {
                filters.splice(k, 1)
            }
        }

        return [filters, fieldExists];
    }

}