import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SharedInitializationService } from '../../shared/shared-common-initialization.service';
import { DatePipe } from '@angular/common';
import { WorkflowNameService } from '../../workflow/services/workflow-name.service';
import { CustomHttpParamEncoder } from '../../shared/custom-http-param-encoder';
import { SharedGeneralFunctionsService } from '../../shared/shared-general-functions.service';
import { MultiStackedBarChart } from 'src/app/shared/d3/d3multistackedbarchart';
import { Bookmark } from '../models/analysis-bookmark.model';
import { AnalysisTab } from '../models/analysis-tab.model';
import { MatchingService } from 'src/app/matching/services/matching.service';

@Injectable()
export class AnalysisService {
  constructor(private appService: AppService,
    private httpClient: HttpClient,
    private workflowNameService: WorkflowNameService,
    private sharedService: SharedInitializationService,
    private sharedFunctionService: SharedGeneralFunctionsService, 
    private matchingService: MatchingService) {
  }

  url = this.appService.getUrl();
  
  //fieldID
  fieldID: any = {};
  //this is for the analysis grid, allows to automatically update the field id
  fieldIDChange: { [index: string]: Subject<string> } = {};
  gridData: any ={};
  gridDataDetails: any = {};
  //this is for the analysis grid, allows to automatically update analysis table
  gridDataChange: { [index: string]: Subject<any[]> } = {};
  gridDataDetailsChange: { [index: string]: Subject<any[]> } = {};
  
  //this is for the analysis grid footer + in the analysis gauss menu
  gridTotalRecordsChange: { [index: string]: Subject<any> } = {};
  gridTotalRecordsDetailsChange: { [index: string]: Subject<any> } = {};



  //this is to change the necessary grid display if bookmark is changed
 
  //this is to change bookmarks both main and details
  bookmarkDataChange: { [index: string]: Subject<Bookmark[]> } = {};
  bookmarkDataDetailsChange: { [index: string]: Subject<Bookmark[]> } = {};



  //init mode
  mode: {} = {}
  //this is called in analysis grid component and automatically updates the mode
  modeChange: { [index: string]: Subject<any> } = {};

  showMenu: {} = {};
  

  histogramCellThreshold: number = 0;
  displayCharts: any = {};

  //this is called in the analysis-grid component and automatically updates the chart data
  statChartTableAttributesChange: { [index: string]: Subject<any[]> } = {};
  columnChartData: any = {};
 
  //fieldID_audit
  fieldID_audit: any = {};

//trueStatusTypePrefix
trueStatusTypePrefix : any = {};
//trueStatusTypePrefixChange: { [index: string]: Subject<string> } = {};
  //init analysis tab changes
  initAnalysisTabChanges(tabs) {
    for (var i in tabs) {
      this.showMenu[tabs[i].name] = true;
      this.displayCharts[tabs[i].name] = false;
      this.columnChartData[tabs[i].name] = [];
      this.gridData[tabs[i].name] = [];
      this.gridDataChange[tabs[i].name] = new Subject<any[]>();
      this.gridTotalRecordsChange[tabs[i].name] = new Subject<any>();
      this.fieldIDChange[tabs[i].name] = new Subject<any>();
     // this.trueStatusTypePrefixChange[tabs[i].name] = new Subject<any>();
      this.bookmarkDataChange[tabs[i].name] = new Subject<Bookmark[]>();
      this.statChartTableAttributesChange[tabs[i].name] = new Subject<any[]>();
      this.mode[tabs[i].name] ="";
      this.modeChange[tabs[i].name] = new Subject<string>();
      if ((tabs[i].type != "Supervised") && (tabs[i].type != 'Bayes') && (tabs[i].type != "Gauss")&& (tabs[i].type != "Kernel")) {
        this.bookmarkDataDetailsChange[tabs[i].name] = new Subject<Bookmark[]>();
        this.gridDataDetails[tabs[i].name] = [];
        this.gridDataDetailsChange[tabs[i].name] = new Subject<any[]>();
        this.gridTotalRecordsDetailsChange[tabs[i].name] = new Subject<any>();
      }
    }

  }


  setHistogramCellThreshold(histogramCellThreshold) {
    this.histogramCellThreshold = histogramCellThreshold;
  }
  getHistogramCellThreshold() {
    return this.histogramCellThreshold;
  }
  setDisplayCharts(withCharts, tab) {
    // if withCharts is true ie: the stats are done at the beginning
    // and the logo of chart is banned
    // the chart arre accesible for each column
    this.displayCharts[tab] = withCharts;
  }
  getDisplayCharts(tab) {
    return this.displayCharts[tab];
  }
  //loads the table referenced by the parameters for the analysis grid
  //called in analysis grid, analysis menu, and analysis menu bayes components
  //  withCharts is a boolean that indicates if the table counts must be done or not for the charts
  // withLoad is a boolean that indicates if the table must be loaded or not
  getAnalysisLoadGrid(workflow, table, subTable, fieldID, startRec, endRec, mode, proMode, filters, sorts, withLoad, withCharts, matchingDetails, tab) {
    this.appService.startSpin2();
    // this.appService.startSpin();
    const url = this.url;
    const webservice = "AnalysisLoadTables";
    const completeUrl = url + webservice;
    console.log("fieldid:", fieldID);
    if (filters === undefined) { filters = "[]"; }
    if (sorts === undefined) { sorts = "[]"; }

    const varFilter = "{\"listOfFilters\":" + filters + "}";

    //let varFilter2 = encodeURIComponent(varFilter);

    const varSort = "{\"listOfSorts\":" + sorts + "}";

    //create new http params
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("segAttrValue", table)
      .set("subTable", subTable)
      //.set("fieldID", fieldID)
      .set("startRec", startRec)
      .set("endRec", endRec)
      .set("mode", mode)
      .set("proMode", proMode)
      .set("Filter", varFilter)
      .set("Sort", varSort)
      .set("withLoad", withLoad)
      .set("withCharts", withCharts)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');



    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        //map(response => new NmUser(response)),
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          if (matchingDetails === false) {
          if (withLoad) {
            this.gridTotalRecordsChange[tab].next(response.tableRows2[0].TotalRecords)
            this.gridData[tab] = response.tableRows;
            this.gridDataChange[tab].next(response.tableRows);
          }
          if (this.mode[tab] != mode) {
            this.mode[tab] = mode;
            let data = { 'mode': mode, 'changeMode': true };
            this.modeChange[tab].next(data)
          }
         

          // new from 20/04// load stat for chart at the same time
          if (withCharts) {
            this.setColumnChartData(response.tableRows3, tab)
          }
          //
        }
        else {
          this.gridDataDetails[tab] = response.tableRows;
          this.gridDataDetailsChange[tab].next(response.tableRows);
          this.gridTotalRecordsDetailsChange[tab].next(response.tableRows2[0].TotalRecords);
        }
        }
        else {
          if ((mode == 'Anomaly')&&(proMode == "normal")&&(subTable == "")&&(response.statusText.trim()=="empty table")){
            // for bayes 
            this.appService.showMessage('Warning', response.statusText)
          }
          else{
            this.appService.showMessage('Error', response.statusText)
          }
        }
        this.appService.stopSpin2();
        // this.appService.stopSpin();
      }, (error) => {

        this.appService.showMessage('Error', error.statusText);
        //  this.appService.stopSpin();
        this.appService.stopSpin2();
      });


  }

   //loads the table referenced by the parameters for the analysis grid
  //called in analysis grid, analysis menu, and analysis menu bayes components
  //  withCharts is a boolean that indicates if the table counts must be done or not for the charts
  // withLoad is a boolean that indicates if the table must be loaded or not
  getAnalysisLoadGridDataHub(workflow, fileName, date,startRec, endRec, filters, sorts, withLoad, tab) {
    this.appService.startSpin2();
    // this.appService.startSpin();
    const url = this.url;
    const webservice = "AnalysisLoadTablesDataHub";
    const completeUrl = url + webservice;

   
    if (filters === undefined) { filters = "[]"; }
    if (sorts === undefined) { sorts = "[]"; }

    const varFilter = "{\"listOfFilters\":" + filters + "}";

    date = date.replace(/-/g, '');

    //let varFilter2 = encodeURIComponent(varFilter);

    const varSort = "{\"listOfSorts\":" + sorts + "}";

    //create new http params
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("fileName", fileName)
      .set("date", date)
      //.set("fieldID", fieldID)
      .set("startRec", startRec)
      .set("endRec", endRec)
      .set("Filter", varFilter)
      .set("Sort", varSort)
      .set("withLoad", withLoad)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');



    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        //map(response => new NmUser(response)),
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {

          if (withLoad) {
            this.gridTotalRecordsChange[tab].next(response.tableRows2[0].TotalRecords)
         //   this.gridData[tab] = response.tableRows;
            this.gridDataChange[tab].next(response.tableRows);
          }
          
        }
        else {
          this.appService.showMessage('Error', response.statusText)
        }
        this.appService.stopSpin2();
        // this.appService.stopSpin();
      }, (error) => {

        this.appService.showMessage('Error', error.statusText);
        //  this.appService.stopSpin();
        this.appService.stopSpin2();
      });


  }

  setColumnChartData(data, tab) {
    this.columnChartData[tab] = [...data];
    this.statChartTableAttributesChange[tab].next(this.columnChartData[tab]);
  }
  getColumnChartData(tab) {
    return this.columnChartData[tab];
  }

  //exports the table referenced by the parameters to a csv file
  //called in analysis grid


  // exportAnalysisGrid(username, workflow, source, segAttribute, table, subTable, fieldID, startRec, endRec, mode, proMode, filters, sorts, fileType, alpha) {
  exportAnalysisGrid(username, workflow, table, subTable, startRec, endRec, mode, proMode, filters, sorts, fileType, alpha, columnsOrder) {

    this.appService.startSpin();
    const url = this.url;
    const webservice = "AnalysisExportTables";
    const completeUrl = url + webservice;

    const varFilter = "{\"listOfFilters\":" + filters + "}";

    //    let varFilter2 = encodeURIComponent(varFilter);

    const varSort = "{\"listOfSorts\":" + sorts + "}";

    //create new http params
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("userName", username)
      .set("wfName", workflow)
      .set("segAttrValue", table)
      .set("subTable", subTable)
      .set("startRec", startRec)
      .set("endRec", endRec)
      .set("mode", mode)
      .set("proMode", proMode)
      .set("Filter", varFilter)
      .set("Sort", varSort)
      .set("fileType", fileType)
      .set("alpha", alpha)
      .set("columnsOrder", columnsOrder)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');



    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        //map(response => new NmUser(response)),
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          let filename = response.tableRows[0].fileName;
          this.analysisDownloadFile(username, filename, fileType, workflow, table)
        }
        else {
          this.appService.showMessage('Error', response.statusText)
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });


  }

  
  // exportAnalysisGrid(username, workflow, source, segAttribute, table, subTable, fieldID, startRec, endRec, mode, proMode, filters, sorts, fileType, alpha) {
    exportAnalysisGridDataHub(username, workflow, date, fileName, startRec, endRec, filters, sorts, fileType, columnsOrder) {

      this.appService.startSpin();
      const url = this.url;
      const webservice = "AnalysisExportTablesDataHub";
      const completeUrl = url + webservice;
  
      const varFilter = "{\"listOfFilters\":" + filters + "}";
  
      //    let varFilter2 = encodeURIComponent(varFilter);
  
      const varSort = "{\"listOfSorts\":" + sorts + "}";
      date = date.replace(/-/g, '');
      //create new http params
      let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
        .set("userName", username)
        .set("wfName", workflow)
        .set("date", date)
        .set("fileName", fileName)
        .set("startRec", startRec)
        .set("endRec", endRec)
        .set("Filter", varFilter)
        .set("Sort", varSort)
        .set("fileType", fileType)
        .set("columnsOrder", columnsOrder)
  
      const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  
  
  
      return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
        .pipe(
          //map(response => new NmUser(response)),
          catchError(this.appService.handleError)
        )
        .subscribe((response: any) => {
  
          //response = response.replace(/[()]/g, '');
          response = response.substring(1, response.length - 1);
          response = JSON.parse(response);
          if (response.statusCode > -1) {
            let filename = response.tableRows[0].fileName;
            this.analysisDownloadFile(username, filename, fileType, workflow, date)
          }
          else {
            this.appService.showMessage('Error', response.statusText)
          }
          this.appService.stopSpin();
        }, (error) => {
          this.appService.showMessage('Error', error.statusText);
          this.appService.stopSpin();
        });
  
  
    }
  

  analysisDownloadFile(username, filename, filetype, workflow, table) {

    const url = this.url;
    const webservice = "AnalysisDownloadFile";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("userName", username)
      .set("fileName", filename)
      .set("fileType", filetype)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'blob', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        let name = workflow + "_" + table;
        if (filetype === "CSV") {
          this.sharedService.downloadCSVFile(response, name);
        }
        else {
          if (filetype === "Excel") {
            this.sharedService.downloadExcelFile(response, name);
          }
        }
        // window.open(downloadURL);


        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

 
  analysisDownloadTypeFile(workflow, table, subtable, filename, filetype, csvData) {

    const url = this.url;
    const webservice = "AnalysisDownloadTypeFile";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    // add 03082022
    let dataList = JSON.stringify(csvData);
    //
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

      .set("wfName", workflow)
      .set("segAttrValue", table)
      .set("subTable", subtable)
      .set("fileName", filename)
      .set("fileType", filetype)
      .set("dataList", dataList)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'blob', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        if (!(response.statusCode)) {
          if (response.type) {
            if (response.type === "application/json") {
              this.appService.showMessage('Error', "impossible to download the file. (You are maybe not authorised to access this service)")

            }
            else {
              if (filetype === "CSV") {
                this.sharedService.downloadCSVFile(csvData, filename);
              }
              else {
                if (filetype === "Excel") {
                  this.sharedService.downloadExcelFile(response, filename);
                }
                if (filetype === "Pivot") {
                  filename = "Pivot_" + filename;
                  this.sharedService.downloadExcelFile(response, filename);
                }
              }
            }
          }
          else {
            this.appService.showMessage('Error', "impossible to download the file")
          }

        } else {
          this.appService.showMessage('Error', response.statusText)
        }


        // window.open(downloadURL);


        this.appService.stopSpin();
      }, (error) => {
        if (error.statusText) {
          this.appService.showMessage('Error', error.statusText);
        }
        else {
          this.appService.showMessage('Error', 'The error may be due to exporting too many rows. Please try to export fewer rows. Thank you.')
        }

        this.appService.stopSpin();
      });
  }
  //this allows to export the help file to pdf
  //called by app component
  analysisDownloadExcelFile(username, filename, workflow, table) {

    const url = this.url;
    const webservice = "AnalysisDownloadExcelFile";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("userName", username)
      .set("wfName", workflow)
      .set("segAttrValue", table)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          let filename = response.tableRows[0].fileName;
          this.analysisDownloadFile(username, filename, "Excel", workflow, table)
        }
        else {
          this.appService.showMessage('Error', response.statusText)
        }



        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }
  // no more used
  //this allows to export the help file to pdf
  //called by app component
  analysisDownloadCSVFile(username, filename, workflow, table, dataList) {

    dataList = this.sharedService.ConvertToCSV(dataList, ";");
    dataList = JSON.stringify(dataList);
    const url = this.url;
    const webservice = "AnalysisDownloadCSVFile";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("userName", username)
      .set("wfName", workflow)
      .set("segAttrValue", table)
      .set("dataList", dataList)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          let filename = response.tableRows[0].fileName;
          this.analysisDownloadFile(username, filename, "CSV", workflow, table)
        }
        else {
          this.appService.showMessage('Error', response.statusText)
        }



        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //this is to download the selected rows, 

  downloadSelectedRows(data, workflow, table) {
    let csvData = this.sharedService.ConvertToCSV(data, ";");
    let name = workflow + "_" + table;
    this.sharedService.downloadCSVFile(csvData, name)
  }
  //sets the field id
  setFieldID(table, tab) {
    this.fieldID[tab] = table.TableDescription.IDField;
    this.fieldIDChange[tab].next(this.fieldID[tab]);
  }

  //returns the field id
  getFieldID(tab) {
    return this.fieldID[tab];
  }
  //sets the TrueStatusTypePrefix
  setTrueStatusTypePrefix(table, tab) {
    if (table.TableDescription.TrueStatusTypePrefix){
    this.trueStatusTypePrefix[tab] = table.TableDescription.TrueStatusTypePrefix;
    }
    else{
      this.trueStatusTypePrefix[tab] = "All";
    }
    // this.trueStatusTypePrefixChange[tab].next(this.fieldID[tab]);
  }
   //returns the trueStatusTypePrefix
   getTrueStatusTypePrefix(tab) {
    return this.trueStatusTypePrefix[tab];
  }
   //sets the field id used for the audit
   setFieldID_audit(fieldID_audit, tab ) {
    this.fieldID_audit[tab] = fieldID_audit;
  }

  //returns the field id
  getFieldID_audit(tab) {
    return this.fieldID_audit[tab];
  }
  //Sets the last user bookmark grid parameters for the workflow/ table/ subtable.
  //called in analysis grid component
  saveBookmark(username, tab, workflow, table, subtable, bookmark, aggregateDetails, tabParam, tabType) {
    const url = this.url;
    const webservice = "AnalysisUserBookmarkSave";
    const completeUrl = url + webservice;


    //  this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("userName", username)
      .set("tabName", tab)
      .set("wfName", workflow)
      .set("segAttrValue", table)
      .set("subTable", subtable)
      .set('bookmark', bookmark)
      .set('aggregateDetails', aggregateDetails)
      .set('tabParam', tabParam)
      .set('tabType', tabType)
      
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          // this.appService.showMessage('Success', response.statusText);
        }
        else {
          //15112020 this.appService.showMessage('Error', response.statusText);
          this.appService.stopSpin();
        }
        //01/08/2020 this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();

      });
  }

  //Loads the last user bookmark grid parameters for the workflow/ table/ subtable.
  //called in analysis grid component
  loadBookmark(username, workflow, table, subtable, details, tab, tabType, tabParam) {
    const url = this.url;
    const webservice = "AnalysisUserBookmarkLoad";
    const completeUrl = url + webservice;
    let navtab = "";
    if (tabType === "navigation") {
      navtab = tab;
      tab = tab.substring(3);
      console.log(tab);
    }

    // this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("userName", username)
      .set("tabName", tab)
      .set("wfName", workflow)
      .set("segAttrValue", table)
      .set("subTable", subtable)
      .set('aggregateDetails', details)
      .set("tabParam", tabParam)
      .set("tabType", tabType)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          if (tabType === "navigation") {
            tab = navtab;
          }
          if (tabParam ==="Analysis") {
            if (details === true) {
              //this.bookmarkDataAggregateChange.next(response.tableRows)
              this.bookmarkDataDetailsChange[tab].next(response.tableRows);
            }
            else {//add27072020
              this.bookmarkDataChange[tab].next(response.tableRows);
            }
          }
          else {
            this.matchingService.bookmarkDataChange[tab].next(response.tableRows);
          }
          
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        //   this.appService.stopSpin();

      }, (error) => {
        //  this.appService.stopSpin();
        this.appService.showMessage('Error', error.statusText);

      });
  }

  //sets mode for analysis services 
  setMode(mode, changeMode, tab) {
    console.group("mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmode")
    this.mode[tab] = mode;
    let data = { 'mode': mode, 'changeMode': changeMode };
    this.modeChange[tab].next(data);

  }
  //for analysis grid (supervised)
  getMode(tab){
    return this.mode[tab]
  }

  setShowMenu(showMenu, tab) {
    this.showMenu[tab] = showMenu;
  }

  getShowMenu(tab) {
    return this.showMenu[tab];
  }
  
}
