import { Injectable, ViewRef } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppService } from '../../../../../app.service';

import { Router } from '@angular/router';
import * as d3 from 'd3';
import { DashboardService } from '../../../../dashboard.service';
import { SharedGeneralFunctionsService } from '../../../../../shared/shared-general-functions.service';


@Injectable()
export class DashboardAxisFormattingService {

    getMargin(vis) {
        if (vis.chartData && vis.chartData.length != 0 && vis.yVariable && vis.yVariable != '') {

            var longest = this.getLongest(vis.yVariable, vis);
            longest = vis.dashboardAxisFormattingService.getTickFormat(longest[vis.yVariable], vis);
            let longestWidth = this.getTextLength(longest);

            if (vis.margin.left === 70 && (vis.margin.left) > longestWidth + 15) {
                vis.margin.left = longestWidth + 30;
            }
        }
        return vis.margin;
    }

    getHeightMarginBottom(vis, height) {

        let number = vis.areaHeight - 30;

        if (vis.graphIndex === 100) {
            number = height - 90;
        }
        if (number <= 100) {
            number = 100;
        }

        let titleHeight = 0;
        let xHeaderHeight = 0;
        let yHeaderHeight = 0;
        let lineChartButtonsHeight = 0;
        let contextMenuHeight = 0;
        if (vis.cd && !(vis.cd as ViewRef).destroyed) {
            vis.cd.detectChanges();
        }

        if (vis.newHeader != "") {
            contextMenuHeight = 26;
        }
        else {



            if (vis.chartContainer.nativeElement.getElementsByClassName("title") && vis.chartContainer.nativeElement.getElementsByClassName("title") != null) {
                if (vis.chartContainer.nativeElement.getElementsByClassName("title")[0]) {
                    titleHeight = vis.chartContainer.nativeElement.getElementsByClassName("title")[0].getBoundingClientRect().height;
                }


                console.log(titleHeight)
            }
            if (vis.chartContainer.nativeElement.getElementsByClassName("xHeader") && vis.chartContainer.nativeElement.getElementsByClassName("xHeader") != null) {
                if (vis.chartContainer.nativeElement.getElementsByClassName("xHeader")[0]) {
                    xHeaderHeight = vis.chartContainer.nativeElement.getElementsByClassName("xHeader")[0].getBoundingClientRect().height;
                }

            }
            if (vis.chartContainer.nativeElement.getElementsByClassName("yHeader") && vis.chartContainer.nativeElement.getElementsByClassName("yHeader") != null) {
                if (vis.chartContainer.nativeElement.getElementsByClassName("yHeader")[0]) {
                    yHeaderHeight = vis.chartContainer.nativeElement.getElementsByClassName("yHeader")[0].getBoundingClientRect().height;
                }

            }
        }

        if (vis.type === "line") {
            if (vis.chartContainer.nativeElement.getElementsByClassName("line-chart-date-buttons") && vis.chartContainer.nativeElement.getElementsByClassName("line-chart-date-buttons") != null) {
                lineChartButtonsHeight = Math.max(vis.chartContainer.nativeElement.getElementsByClassName("line-chart-date-buttons")[0].getBoundingClientRect().height, 24);
            }

        }

        vis.height = number - titleHeight - xHeaderHeight - yHeaderHeight - lineChartButtonsHeight - contextMenuHeight;

        if (vis.height <= 100) {
            vis.height = 100;
        }

        return vis;

    }

    getTextLength(value) {

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        //  context.font = font || getComputedStyle(document.body).font;

        let textLength = context.measureText(value).width;

        return textLength;
    }

    getLongest(variable, vis) {
        var longest = vis.chartData.reduce(
            function (a, b) {
                if (a[variable] && b[variable]) {
                    return a[variable].length > b[variable].length ? a : b;
                }
                else {
                    return a;
                }
            }
        );
        return longest;
    }

    getYDividor(variable, type, vis) {
        let dividor = 1;
        let data = [...vis.dataFiltered]
        if (type === "notnumeric") {
            let domain = vis.dataFiltered.map(a => a[variable])
            data = domain.filter((item, i, ar) => ar.indexOf(item) === i);
        }

        dividor = Math.ceil(data.length / (vis.height / 32));

        return dividor;
    }

    getXDividor(variable, vis, chartData) {
        let xDividor = 1;
        if (chartData.length != 0) {
            var xLongest = this.getLongest(variable, vis);
            xLongest = vis.dashboardAxisFormattingService.getTickFormat(xLongest[variable], vis);
            let xLongestWidth = this.getTextLength(xLongest) + 50;

            let domain = chartData.map(a => a[variable])
            let unique = domain.filter((item, i, ar) => ar.indexOf(item) === i);

            xDividor = Math.ceil(unique.length / (vis.width / xLongestWidth));
        }


        return xDividor;
    }

    getValueEllipsis(d, vis) {
        let textLength = this.getTextLength(d);
        let dotTextLength = this.getTextLength("...");
        if (textLength > (vis.margin.left - 15) && d.length > 0) {
            while ((textLength + dotTextLength) > (vis.margin.left - 15) && d.length > 0) {
                d = d.slice(0, -1);
                textLength = this.getTextLength(d);
            }
            d = d + '...';
        }
        return d;
    }

    getTickFormat(d, vis) {
        if (!d) {
            return d;
        }
        else if (typeof d === "string") {

            return d;
        }
        else {
            if (Math.abs(d) >= 1000000000) {
                d = Intl.NumberFormat('en-US', { minimumFractionDigits: vis.numericDecimal, maximumFractionDigits: vis.numericDecimal }).format(d / 1000000000)
                return d + "B"
            }
            else if ((Math.abs(d) >= 1000000) && (Math.abs(d) < 1000000000)) {
                d = Intl.NumberFormat('en-US', { minimumFractionDigits: vis.numericDecimal, maximumFractionDigits: vis.numericDecimal }).format(d / 1000000)
                return d + "M"
            }
            else if (Math.abs(d) >= 1000) {
                d = Intl.NumberFormat('en-US', { minimumFractionDigits: vis.numericDecimal, maximumFractionDigits: vis.numericDecimal }).format(d / 1000)
                return d + "K"
            }
            else {
                d = Intl.NumberFormat('en-US', { minimumFractionDigits: vis.numericDecimal, maximumFractionDigits: vis.numericDecimal }).format(d)
                return d;
            }
        }
    }

    getAxisColors(scale, vis) {

        let tags = ["line", "path"];

        for (var i in tags) {
            vis[scale.axisName].selectAll(tags[i])
                .style("stroke", "#bbbbbb");
        }

        vis[scale.axisName].selectAll("text")
            .style("fill", "rgb(84, 84, 84)");
    }

}