import { Injectable, ViewRef } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';



import * as d3 from 'd3';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { DashboardFilterChartService } from '../../../dashboard-services/dashboard-filter-chart.service';
import { DashboardBarChartService } from '../dashboard-bar-chart.service';
import { DashboardBubbleChartService } from '../dashboard-bubble-chart.service';
import { DashboardComboChartService } from '../dashboard-combo-chart.service';
import { DashboardLineChartService } from '../dashboard-line-chart.service';



@Injectable()
export class DashboardChartService {

    constructor(
        private dashboardService: DashboardService,
        private dashboardComboChartService: DashboardComboChartService,
        private dashboardBarChartService: DashboardBarChartService,
        private dashboardLineChartService: DashboardLineChartService,
        private dashboardBubbleChartService: DashboardBubbleChartService,
        private dashboardFilterChartService: DashboardFilterChartService
    ) {}


    getHeader(vis) {
        let header = vis.header;

        if (vis.newHeader != "") {
            header = vis.newHeader;
        }

        return header;
    }

    formatChartData(systems, aggregChartData, chartData, chartData2, aggregatedDimensionMethod, aggDimensionMethod, aggMethod, top, numericHeader, dateBucketing, numericDecimal, datePeriod, datePeriodValue, datePeriodFixedDate, mainTab, displayOther, dateHeader, vis) {
        if (aggregatedDimensionMethod && (aggregatedDimensionMethod != "") && (aggregatedDimensionMethod != "none")) {
            systems = [];
            let aggregChartData;
            let removeDates;
            [aggregChartData, systems, removeDates] = this.dashboardService.formatDashboardData(chartData, "col", "", "", aggregatedDimensionMethod, "col", numericHeader, dateBucketing, numericDecimal, datePeriod, datePeriodValue, datePeriodFixedDate, mainTab);
            chartData = JSON.parse(JSON.stringify(aggregChartData))

        }

        chartData.sort((a, b) => (a.col > b.col) ? 1 : ((b.col > a.col) ? -1 : 0));
        console.log(chartData);

        chartData2 = JSON.parse(JSON.stringify(chartData));

        if (chartData2.length > top) {

            let newdata = chartData2.splice(0, chartData2.length - top);
            console.log("newdata");
            console.log(newdata);
            let othersDataCol = this.getOthersDataValue(aggDimensionMethod, aggMethod, newdata, chartData2, "col");



            let othersData = { Categorie: "Other", col: othersDataCol, colCombo: '', xHeader: "Other", yHeader: "Other" }

            if (vis.type === "combo") {
                let othersDataColCombo = this.getOthersDataValue(aggDimensionMethod, vis.aggMethodCombo, newdata, chartData2, 'colCombo');
                othersData = { Categorie: "Other", col: othersDataCol, colCombo: othersDataColCombo, xHeader: "0", yHeader: "0" }
            }
            chartData.splice(0, chartData.length - top);
            if (displayOther) {
                chartData.unshift(othersData);
            }



            console.log(chartData)

        }
        return chartData;
    }

    getOthersDataValue(aggDimensionMethod, aggMethod, newdata, chartData2, col) {
        let othersDataCol;

        if ((aggDimensionMethod === "count") || (aggMethod === "count") || (!aggMethod) || (aggMethod === "sum")) {
            othersDataCol = newdata.reduce(function (a, b) { return +a + (+b[col]); }, 0);
        }

        else if ((aggMethod === "min") || (aggMethod === "max")) {
            for (var i in newdata) {
                if (!othersDataCol) {
                    othersDataCol = newdata[i][col]
                }
                else {
                    othersDataCol = Math[aggMethod](othersDataCol, newdata[i][col])
                }

            }
        }

        else if (aggMethod === "average") {
            let sum = newdata.reduce(function (a, b) { return a + b[col] * b.number; }, 0);
            let count = newdata.reduce(function (a, b) { return a + b.number; }, 0);
            othersDataCol = sum / count;
        }

        return othersDataCol;
    }

    initChartVariables(vis) {
        vis.parentId = "#" + vis.type + vis.header + vis.tabAlias + vis.mainTab + vis.graphIndex;
        vis.xVariable = "col";
        vis.yVariable = "Categorie";
        vis.zVariable = "col";
        if (vis.type === "bubble") {
            vis.xVariable = "xHeader";
            vis.yVariable = "yHeader";
        }
        else if ((vis.type === "line") || (vis.type === "combo")) {
            vis.xVariable = "Categorie";
            vis.yVariable = "col";
        }
        vis.color = "#0daaba";
        if (vis.colorScheme) {
            if (vis.colorScheme[0]) {
                vis.color = vis.colorScheme[0];
            }
        }

        vis.clientWidth = vis.areaWidth * 0.9;
        if (vis.clientWidth < 150) {
            vis.clientWidth = 150;
        }
        vis.width = vis.clientWidth;

        if (vis.header != "") {
            if (!vis.margin.changed) {
                vis.margin = vis.dashboardAxisFormattingService.getMargin(vis);
            }

            vis = vis.dashboardAxisFormattingService.getHeightMarginBottom(vis, vis.areaHeight);
        }
        else {
            let number = vis.areaHeight - 30;

            if (vis.graphIndex === 100) {
                number = vis.areaHeight - 90;
            }

            if (number <= 100) {
                number = 100;
            }
            vis.height = number;
        }




        //   let domain = this.chartData[this.attribute.AttrName].map(a => a["Categorie"]);
        vis.domain = vis.chartData.map(a => a["Categorie"]);

        if (vis.data[0]) {
            let keys = Object.keys(vis.data[0]);
            vis.headers = [];
            for (var i in keys) {
                let newdata = {
                    name: keys[i]
                }
                vis.headers.push(newdata)
            }

        }

        return vis;
    }

    
    initVisualization(vis) {
        vis.height = Math.round(vis.height - vis.margin.top - vis.margin.bottom);
        vis.width = Math.round(vis.width - vis.margin.left - vis.margin.right);

        vis.clip = "clip" + vis.parentId.substr(1);
        vis.clipx = "clipx" + vis.parentId.substr(1);
        vis.clipy = "clipy" + vis.parentId.substr(1);
        var id = d3.select(vis.parentId);
        id.selectAll('svg').remove();

        //   d3.selectAll(this.parentId).remove('svg');
        vis.svg = d3.select(vis.parentId)
            .append('svg')
            //   .attr("viewBox", [0, 0, vis.width + vis.margin.left + vis.margin.right, vis.height + vis.margin.bottom + vis.margin.top])
            .attr("sharp-rendering", "crispEdges")
            .attr('width', vis.width + vis.margin.left + vis.margin.right)
            .attr('height', vis.height + vis.margin.bottom + vis.margin.top)

        console.log(vis.margin.left)



        vis.g = vis.svg.append("g")
            .attr("transform", "translate(" + vis.margin.left + ", " + vis.margin.top + ")")
        //   .attr("clip-path", "url(#"+vis.clip+")")
        vis.g1 = vis.svg.append("g")
            .attr("transform", "translate(" + vis.margin.left + ", " + vis.margin.top + ")")
            .attr("clip-path", "url(#" + vis.clip + ")")
        vis.g2 = vis.svg.append("g")
            .attr("transform", "translate(" + vis.margin.left + ", " + vis.margin.top + ")")
            .attr("clip-path", "url(#" + vis.clip + ")")
        vis.barG = vis.svg.append("g")
            .attr("class", "bar y axis container")
            .attr("transform", "translate(" + vis.margin.left + ", " + vis.margin.top + ")")
        //.attr("clip-path", "url(#" + vis.clip + ")")


        let g3Width = vis.width - vis.margin.right + vis.margin.left;
        vis.g3 = vis.svg.append("g")
            .attr("transform", "translate(" + g3Width +
                ", " + vis.margin.top + ")")

        vis.pieG = vis.svg.append("g")
            .attr("transform", "translate(" + (vis.width + vis.margin.right + vis.margin.left) / 2 +
                ", " + (vis.height + vis.margin.top + vis.margin.bottom) / 2 + ")")
        vis.t = () => { return d3.transition().duration(1000) }

        vis.bisectX = d3.bisector(function (d) { return d[vis.xVariable]; }).left;
        vis.bisectY = d3.bisector(function (d) { return d[vis.yVariable]; }).left;

        vis.xWidth = vis.width - vis.margin.right;

        vis.y = d3.scaleBand()
            .range([vis.height, 0])
            .padding(0.5)

        vis.x = d3.scaleLinear()
            .range([0, vis.xWidth]);

        //init clip paths to make sure that the graph (line/scatter/)stays within chart limits when zooming
        vis.defs = vis.g.append('defs');

        if (vis.type != "bubble" && vis.type != "pie") {


            vis.defs
                .append('svg:clipPath')
                .attr('id', vis.clipy)
                .attr("class", "clipy")
                .append('svg:rect')
                .attr('x', -vis.margin.left + 2)
                .attr('y', 0)
                .attr('width', vis.margin.left)
                .attr('height', vis.height);

            vis.defs
                .append('svg:clipPath')
                .attr('id', vis.clipx)
                .attr("class", "clipx")
                .append('svg:rect')
                .attr('x', 0)
                .attr('y', vis.height)
                .attr('width', vis.width)
                .attr('height', vis.margin.bottom);


            vis.defs
                .append('svg:clipPath')
                .attr('id', vis.clip)
                .attr("class", "clip")
                .append('svg:rect')
                .attr('x', 0)
                .attr('y', -vis.margin.top)
                .attr('width', vis.width + vis.margin.right)
                .attr('height', vis.height + vis.margin.top);


        }


        vis.focus = vis.g.append('g')
            .attr("class", "focus")
            .style('display', 'none')



      /*  vis.yAxisCall = d3.axisLeft()
            .ticks(4)
            .tickFormat(function (d, i) {
                d = vis.dashboardAxisFormattingService.getTickFormat(d, vis)
                d = vis.dashboardAxisFormattingService.getValueEllipsis(d, vis);

                return d;
            })
 */
        if (vis.type === "combo") {
            vis.y1Axis = vis.g3.append("g")
                .attr("class", "y axis");
        }

     /*   vis.xAxisCall = d3.axisBottom()
            .ticks(4)
            .tickFormat(function (d) {
                return d;
            }); */
        //x and y axis are appended in html
        let xAxisHeight = vis.height + vis.margin.top;
        vis.xAxis = vis.svg.append("g")
            //.attr('clip-path', 'url(#' + vis.clipx + ')')
            //.append("g")
            .attr("class", "x axis")
            .attr("transform", "translate(" + vis.margin.left + "," + xAxisHeight + ")")
            .attr("dx", "0.71em")
            .style("z-index", 20000)



        if (vis.type != "bar") {
            vis.yAxis = vis.g.append("g")
                //.attr('clip-path', 'url(#' + vis.clipy + ')')
                //  .append("g")
                .attr("class", "y axis");
        }


        return vis;
    }


    setXandYaxis(vis) {
        if (vis.dataFiltered.length === 0) {
            vis.x.domain([0, 1]);
            vis.y.domain([0, 1]);
            if (vis.z) {
                vis.z.domain([0, 1]);
            }

        }

        if (vis.dateBucketing === "Year") {
            vis.dateFormat.name = "YYYY";
        }
        let dateFormat = vis.dashboardService.timeFormat(vis.dateFormat.name)
        var timeFormat = d3.timeFormat(dateFormat);
        function multiFormat(date) {
            console.log(timeFormat(date));
            return timeFormat(new Date(date));
        }

        vis.multiFormat = multiFormat;
        vis.yDividor = Math.ceil(vis.chartData.length / (vis.height / 32));
          
        if (vis.header != "") {
            vis.xDividor = vis.dashboardAxisFormattingService.getXDividor(vis.xVariable, vis, vis.chartData);
        }
        else {
            vis.xDividor = 1;
        }

        if (vis.type === "bubble") {
            vis = this.dashboardBubbleChartService.getBubbleXandY(vis);
        }
        else if (vis.type === "bar") {
            vis = this.dashboardBarChartService.getBarXandY(vis);
        }
        else if (vis.type === "line") {
            let header = this.getHeader(vis);
            vis = this.dashboardLineChartService.getLineXandY(vis, multiFormat, header);
        }
        else if (vis.type === "combo") {
            vis = this.dashboardComboChartService.getComboXandY(vis);

        } 

        // initialize legend (get data keys, get color and show rect + text)
        if (vis.g) {
            vis.g.selectAll(".legendClass").remove();
        }


        return vis;

    }


    redrawChart(width, height, margin, param, vis) {
        if (param != "notExpandedCollapsed") {
            vis.expandedChart = !vis.expandedChart;
        }

        vis.margin = margin;


        vis.height = Math.round(height - vis.margin.top - vis.margin.bottom);
        vis.width = Math.round(width - vis.margin.left - vis.margin.right);



        vis.svg
            // .attr("viewBox", [0, 0, vis.width + vis.margin.left + vis.margin.right, vis.height])
            .attr("width", vis.width + vis.margin.left + vis.margin.right)
            .attr("height", vis.height + vis.margin.top + vis.margin.bottom)
            .attr("sharp-rendering", "crispEdges")

        vis.g2
            .attr("transform", "translate(" + vis.margin.left +
                ", " + vis.margin.top + ")");

        vis.g1
            .attr("transform", "translate(" + vis.margin.left +
                ", " + vis.margin.top + ")");
        vis.g
            .attr("transform", "translate(" + vis.margin.left +
                ", " + vis.margin.top + ")");

        vis.barG
            .attr("transform", "translate(" + vis.margin.left +
                ", " + vis.margin.top + ")");

        vis.xWidth = vis.width - vis.margin.right;

        let g3Width = vis.width - vis.margin.right + vis.margin.left;
        vis.g3
            .attr("transform", "translate(" + g3Width +
                ", " + vis.margin.top + ")")

        if (vis.type === "bar") {
            vis.y = d3.scaleBand()
                .range([vis.height, 0])
                .padding(0.5)

            vis.x = d3.scaleLinear()
                .range([0, vis.xWidth]);

            if (vis.barYaxis) {
                vis.barYaxis
                .attr("transform", "translate(" + vis.margin.left + ",0)")

            }
          
            if (vis.x.domain()[0] <= 0 && vis.barYaxis) {
                vis.x.domain()[0] = -Math.max(Math.abs(vis.x.domain()[0]), Math.abs(vis.x.domain()[1]));
                vis.x.domain()[1] = - vis.x.domain()[0];
                vis.barYaxis
                    .attr("transform", "translate(" + vis.x(0) + ",0)")
            }
        }
        else if (vis.type === "bubble") {
            vis.y = d3.scaleLinear()
                .range([vis.height, 0])
            // .padding(0.5)

            vis.x = d3.scaleLinear()
                .range([0, vis.xWidth]);
        }
        else if (vis.type === "pie") {
            // vis.width = width;
            //  vis.height = height;
            vis.pieG
                .attr("transform", "translate(" + (vis.width + vis.margin.right) / 2 +
                    ", " + (vis.height + vis.margin.top + vis.margin.bottom) / 2 + ")")
        }

        let xAxisHeight = vis.height + vis.margin.top;

        vis.xAxis
            .attr("transform", "translate(" + vis.margin.left + "," + xAxisHeight + ")")

        vis.defs.selectAll("#" + vis.clipx).selectAll("rect")
            .attr('x', -10)
            .attr('y', vis.height)
            .attr('width', vis.width)
            .attr('height', vis.margin.bottom);

        if (vis.type != "bubble") {
            vis.defs.selectAll("#" + vis.clip).selectAll("rect")
                //.attr('id', 'clip')
                .attr('x', 0)
                .attr('y', -10)
                .attr('width', vis.xWidth)
                .attr('height', vis.height + vis.margin.top);
        }

        vis.defs.selectAll("#" + vis.clipy).selectAll("rect")
            //.attr('id', 'clip')
            .attr('x', -vis.margin.left + 2)
            .attr('y', 0)
            .attr('width', vis.margin.left)
            .attr('height', vis.height)

        if (vis.type === "treemap") {
            vis.width = width;
            vis.height = height;
        }

        return vis;
    }

  
   
}