/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cardabel-tooltip.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./cardabel-tooltip.component";
var styles_CardabelTooltipComponent = [i0.styles];
var RenderType_CardabelTooltipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardabelTooltipComponent, data: {} });
export { RenderType_CardabelTooltipComponent as RenderType_CardabelTooltipComponent };
function View_CardabelTooltipComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "cardabel-tooltip"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 0, 0, currVal_0); }); }
export function View_CardabelTooltipComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardabelTooltipComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.text && (_co.text != "")); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CardabelTooltipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cardabel-tooltip", [], null, null, null, View_CardabelTooltipComponent_0, RenderType_CardabelTooltipComponent)), i1.ɵdid(1, 114688, null, 0, i3.CardabelTooltipComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardabelTooltipComponentNgFactory = i1.ɵccf("app-cardabel-tooltip", i3.CardabelTooltipComponent, View_CardabelTooltipComponent_Host_0, { text: "text" }, {}, []);
export { CardabelTooltipComponentNgFactory as CardabelTooltipComponentNgFactory };
