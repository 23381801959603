import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class CardabelVirtualFilterService {

    public filterText: any;
    public filterTextChange = new Subject<any>();
    public numericMinChange = new Subject<any>();
    public numericMaxChange = new Subject<any>();
    public dateToChange = new Subject<any>();
    public dateFromChange = new Subject<any>();
    public selectAllRowsChange = new Subject<any>();
    public numericFilterAndOrChange = new Subject<any>();
    public inNotConditionChange = new Subject<any>();
    public numberSelectedCheckChange = new Subject<any>();
    public numberSelectedCheck = [];

    returnFilters(filterSliceStream, filters) {

        for (var i in filters) {
            var value = filters[i].value;
            var field = filters[i].field;
            var type = filters[i].filterType;
            var value2 = filters[i].value2;
            var condition = filters[i].filterCondition;

            if ((type === "greater") || (type === "less")) {
                if (typeof value === "string") {
                    if (value.indexOf("K") > -1) {
                        value = value.substr(0, value.indexOf('K'));
                        value = +value * 1000;
                    }
                    else if (value.indexOf("k") > -1) {
                        value = value.substr(0, value.indexOf('k'));
                        value = +value * 1000;
                    }
                    else if (value.indexOf("M") > -1) {
                        value = value.substr(0, value.indexOf('M'));
                        value = +value * 1000000;
                    }
                    else if (value.indexOf("m") > -1) {
                        value = value.substr(0, value.indexOf('m'));
                        value = +value * 1000000;
                    }
                    else if (value.toString() != "") {
                        value = +value;
                    }
                }
                if (typeof value2 === "string") {
                    if (value2.indexOf("K") > -1) {
                        value2 = value2.substr(0, value2.indexOf('K'));
                        value2 = +value2 * 1000;
                    }
                    else if (value2.indexOf("k") > -1) {
                        value2 = value2.substr(0, value2.indexOf('k'));
                        value2 = +value2 * 1000;
                    }
                    else if (value2.indexOf("M") > -1) {
                        value2 = value2.substr(0, value2.indexOf('M'));
                        value2 = +value2 * 1000000;
                    }
                    else if (value2.indexOf("m") > -1) {
                        value2 = value2.substr(0, value2.indexOf('m'));
                        value2 = +value2 * 1000000;
                    }
                    else if (value2.toString() != "") {
                        value2 = +value2;
                    }
                }


            }

            if ((type === "dateFrom") || (type === "dateTo")) {
                value = new Date(value);

                if ((value2 != undefined) && (value2 != null) && (value2 != "")) {
                    value2 = new Date(value2)
                }
            }

            filterSliceStream = filterSliceStream.filter(function (item) {

                //if (type === 'isEmpty') {
                if (type === 'empty') {
                    if ((item[field] != undefined) && (item[field] != null) && (item[field]!= '')) {
                        return false;
                    }
                }
                else if (type === 'notempty') {//if (type === 'isNotEmpty') {
                    if ((item[field] === undefined) || (item[field] === null) || (item[field] === '')) {
                        return false;
                    }
                }
                else if (type === '+') {
                    if ((item[field] === undefined) || (item[field] === null)) {
                        return false;
                    }
                    else if ((value != "") && (value != undefined)) {
                        if (item[field].toString().toLowerCase().indexOf(value.toString().toLowerCase()) === -1) {
                            return false;
                        }
                    }

                }
                else if (type === '-') {
                    if ((value != "") && (value != undefined) && (item[field] != undefined) && (item[field] != null)) {
                        if (item[field].toString().toLowerCase().indexOf(value.toString().toLowerCase()) > -1) {
                            return false;
                        }
                    }
                }
                else if (type === '=') {
                    if ((value != "") && (value != undefined) && (item[field] != undefined) && (item[field] != null)) {
                        if (item[field].toString().toLowerCase() != value.toString().toLowerCase()) {
                            return false;
                        }
                    }

                }
                else if (type === '!') {
                    if ((value != "") && (value != undefined) && (item[field] != undefined) && (item[field] != null)) {
                        if (item[field].toString().toLowerCase() === value.toString().toLowerCase()) {
                            return false;
                        }
                    }

                }
                else if (type === 'in') {
                    let returnValue1 = true;
                    let returnValue2 = false;
                    if (condition === 'NOT') {
                        returnValue1 = false;
                        returnValue2 = true;
                    }
                    for (var i in value) {
                        if (item[field] === null) {
                            if (value[i] === "null") {
                                value[i] = null;
                            }

                            if (item[field] === value[i]) {
                                return returnValue1;
                            }
                        }

                        else if (typeof item[field] === 'string') {
                            if ((value[i] != 'null') && (value[i] != null)) {
                                if (item[field].toString().toLowerCase() === (value[i].toLowerCase())) {
                                    return returnValue1;
                                }
                            }
                        }
                        else if (typeof item[field] === 'number') {
                            if ((value[i] != 'null') && (value[i] != null)) {
                                value[i] = +value[i];
                            }


                            if (item[field] === value[i]) {
                                return returnValue1;
                            }
                        }

                    }
                    return returnValue2;
                }
                else if (((type === 'greater') || (type === 'less')) && (item[field] != undefined) && (item[field] != null)) {

                    if ((value2 != undefined) && (value2 != null) && (value2.toString() != "")) {


                        if (condition === true) {
                            if (type === "greater") {
                                if (item[field] >= value) {
                                    if (item[field] <= value2) {
                                        return true;
                                    }
                                    else {
                                        return false;
                                    }
                                }
                                else {
                                    return false;
                                }
                            }
                            else {
                                if (item[field] <= value) {
                                    if (item[field] >= value2) {
                                        return true;
                                    }
                                    else {
                                        return false;
                                    }
                                }
                                else {
                                    return false;
                                }

                            }

                        }
                        else {
                            if (type === 'greater') {
                                if (item[field] >= value) {
                                    return true
                                }
                                else if (item[field] <= value2) {
                                    return true;
                                }
                                return false;
                            }
                            else if (type === 'less') {
                                if (item[field] <= value) {
                                    return true
                                }
                                else if (item[field] >= value2) {
                                    return true;
                                }
                                return false;
                            }
                        }
                    }
                    else if (type === "greater") {
                        if (value.toString() != "") {
                            if (item[field] < value) {
                                return false;
                            }
                        }

                    }
                    else if (type === 'less') {
                        if (value.toString() != "") {
                            if (item[field] > value) {
                                return false;
                            }
                        }
                    }

                }

                else if (((type === 'dateFrom') || (type === 'dateTo')) && (item[field] != undefined) && (item[field] != null)) {
                    let newDate = new Date(item[field]);

                    if ((value2) && (value2 != "") && (value2 != null)) {

                        if (type === 'dateFrom') {
                            if ((newDate >= value) && (newDate <= value2)) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                        else if (type === 'dateTo') {
                            if ((newDate <= value) && (newDate >= value2)) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                    }
                    else if (type === 'dateFrom') {
                        if (newDate <= value) {
                            return false;
                        }
                    }
                    else if (type === 'dateTo') {
                        if (newDate >= value) {
                            return false;
                        }
                    }
                }



                return true;
            })


        };

        return filterSliceStream;
    }

    formatFilters(filters, filterField, filterValue, filterType, filterValue2, filterCondition, inputType) {
        let fieldExists = false;

        let i = filters.findIndex(item => item.field === filterField);

        if (i != -1) {
            filters[i].value = filterValue;
            filters[i].value2 = filterValue2;

            filters[i].inputType = inputType;
            filters[i].filterType = filterType;
            filters[i].filterCondition = filterCondition;

            if ((filterType === 'greater') || (filterType === 'less')) {
                if (filters[i].value === "") {
                    if ((filterValue2 != null) && (filterValue2) && (filterValue2 != "")) {
                        filters[i].value = filterValue2;
                        filters[i].value2 = "";
                        if (filterType === "greater") {
                            filters[i].filterType = "less";
                        }
                        else {
                            filters[i].filterType = "greater";
                        }
                    }
                }
            }

            if ((filterType === 'dateFrom') || (filterType === 'dateTo')) {
                if (filters[i].value === "") {
                    if ((filterValue2 != null) && (filterValue2) && (filterValue2 != "")) {
                        filters[i].value = filterValue2;
                        filters[i].value2 = "";
                        if (filterType === "dateFrom") {
                            filters[i].filterType = "dateTo";
                        }
                        else {
                            filters[i].filterType = "dateFrom";
                        }
                    }
                }
            }
        }
        else {
            filters.push({ field: filterField, value: filterValue, filterType: filterType, value2: filterValue2, filterCondition: filterCondition, inputType: inputType })
        }

        for (var k = filters.length - 1; k > -1; k--) {
            if ((filters[k].filterType!='empty') &&(filters[k].filterType!='notempty') && (((filters[k].value === "") && (filters[k].value2 === "") ) || filters[k].value === null || filters[k].value2 === null)) {
                filters.splice(k, 1)
            }
        }

        return filters;
    }

    getFilterText() {
        return this.filterText;
    }

    setFilterText(filterText) {
        this.filterText = filterText;
        this.filterTextChange.next(filterText);
    }

    changeFilterText(filterText, numericMin, numericMax, dateTo, dateFrom, numericFilterAndOr, selectAllRows, inNotCondition, numberSelectedCheck) {
        this.filterText = filterText;
        this.filterTextChange.next(filterText);
        this.numericMinChange.next(numericMin);
        this.numericMaxChange.next(numericMax);
        this.dateToChange.next(dateTo);
        this.dateFromChange.next(dateFrom);
        this.numericFilterAndOrChange.next(numericFilterAndOr);
        this.selectAllRowsChange.next(selectAllRows);
        this.numberSelectedCheckChange.next(numberSelectedCheck)
        this.inNotConditionChange.next(inNotCondition);
        this.numberSelectedCheck = numberSelectedCheck;
    }

    changeGlobalFilter(activeGlobalIcon, globalFiltersArray, globalFilter, globalFilterText, filters, filterText) {
        if (!activeGlobalIcon) {
            for (var i in globalFiltersArray) {
                globalFilter[globalFiltersArray[i].name] = false;
                globalFilterText[globalFiltersArray[i].name] = '';
            }

            globalFiltersArray = []
        }
        else {
            for (var i in filters) {
                globalFilter[filters[i].field] = true;
                globalFilterText[filters[i].field] = filters[i].filterType + " " + filters[i].value;

                if (filters[i].filterType === 'in') {
                    if (filters[i].filterCondition === "NOT") {
                        globalFilterText[filters[i].field] = "not " + filters[i].filterType + " " + filters[i].value;
                    }
                }
                filterText[filters[i].field] = '';

                let fieldExists = false;
                for (var j in globalFiltersArray) {
                    if (globalFiltersArray[j].field === filters[i].field) {
                        if ((globalFiltersArray[j].inputType === "numeric") || globalFiltersArray[j].inputType === "date") {
                            if (globalFiltersArray[j].filterType === filters[i].filterType) {
                                globalFiltersArray[j] = filters[i];
                                fieldExists = true;
                                break;
                            }
                        }
                        else {
                            globalFiltersArray[j] = filters[i];
                            fieldExists = true;
                            break;
                        }


                    }
                }

                if (fieldExists === false) {
                    globalFiltersArray.push(filters[i])
                }
            }
        }

        return [globalFiltersArray, globalFilter, globalFilterText, filterText]

    }

    resetFilterText(column, filterText, dateTo, dateFrom, numericMin, numericMax, numericFilterAndOr, inNotCondition, selectAllRows, numberSelectedCheck) {
        for (var i in column) {
            if (column[i].type === "string") {
                filterText[column[i].name] = "";
            }
            else if (column[i].type === "date") {
                dateTo[column[i].name] = "";
                dateFrom[column[i].name] = "";
            }
            else {
                numericMin[column[i].name] = "";
                numericMax[column[i].name] = "";
                numericFilterAndOr[column[i].name] = true;
            }

            if ((column[i].name != "C") && (column[i].name != "R") && (column[i].name != "guiIndex")) {
                selectAllRows[column[i].name] = false;
                //this.selectAll(this.column[i].name)
            }
            inNotCondition[column[i].name] = "IN";
            numberSelectedCheck[column[i].name] = "All";
        }
        this.numberSelectedCheck = [];
        this.filterText = filterText;
        return [filterText, dateTo, dateFrom, numericMin, numericMax, numericFilterAndOr, inNotCondition, selectAllRows, numberSelectedCheck]
    }

    setGlobalFilterCSS(globalFiltersArray, globalFilters, global, activeGlobalIcon, globalFilter, globalFilterText, numericFilterAndOr, globalSorts, globalSort, globalSortText) {

        globalFiltersArray = JSON.parse(JSON.stringify(globalFilters))

        if (global === true) {
            activeGlobalIcon = true;
            globalFilter = {};
            globalFilterText = {};
            for (var i in globalFilters) {
                globalFilter[globalFilters[i].field] = true;
                numericFilterAndOr[globalFilters[i].field] = globalFilters[i].filterCondition;
                let filterType = globalFilters[i].filterType;
                if (filterType === "dateTo") {
                    filterType = "to"
                }
                else if (filterType === "dateFrom") {
                    filterType = "from"
                }
                globalFilterText[globalFilters[i].field] = filterType + " " + globalFilters[i].value;

                if (globalFilters[i].value2 != '') {
                    let filterType2 = "";

                    if (globalFilters[i].filterType === "dateTo") {
                        filterType2 = "from"
                    }
                    else if (globalFilters[i].filterType === "dateFrom") {
                        filterType2 = "to";
                    }
                    else if (globalFilters[i].filterType === "less") {
                        filterType2 = "greater"
                    }
                    else if (globalFilters[i].filterType === "greater") {
                        filterType2 = "less"
                    }
                    globalFilterText[globalFilters[i].field] = globalFilterText[globalFilters[i].field] + " " + filterType2 + " " + globalFilters[i].value2;
                }



                if (globalFilters[i].filterType === 'in') {
                    if (globalFilters[i].filterCondition === 'NOT') {
                        globalFilterText[globalFilters[i].field] = "not " + globalFilters[i].filterType + " " + globalFilters[i].value;
                    }
                }

            }

            globalSort = {};
            globalSortText = {};
            for (var k in globalSorts) {
                globalSort[globalSorts[k].field] = true;
                globalSortText[globalSorts[k].field] = globalSorts[k].direction;
                //this.column = this.service.setSortOnColumnArray(this.sortColumns[i].field, this.column, null);
            }


        }
        else {
            activeGlobalIcon = false;
            globalFilter = {};
            globalFilterText = {};
            globalSort = {};
            globalSortText = {};
        }

        return [activeGlobalIcon, globalFiltersArray, globalFilter, globalFilterText, numericFilterAndOr, globalSort, globalSortText]
    }

    removeFilter(header, type, from, filterType, groupedColumn, alreadyGrouped, filterText, filtered, numberSelectedCheck, globalFilterText, numericMin, numericMax, dateTo, dateFrom, selectAllRows, globalFiltersArray, globalFilter) {
        groupedColumn = false;
        alreadyGrouped = false;

        if (type === "string") {
            filterText[header] = '';
            filtered[header] = [];
            numberSelectedCheck[header] = 'All';
            globalFilterText[header] = "";

        }
        else if (type === "numeric") {
            if (from === "column") {
                numericMin[header] = "";
                numericMax[header] = "";
            }
            else {
                if (filterType === "greater") {
                    numericMin[header] = "";
                }
                else {
                    numericMax[header] = "";
                }
            }

        }
        else if (type === "date") {
            dateTo[header] = "";
            dateFrom[header] = "";
            if (filterType === "dateTo") {
                dateTo[header] = "";
            }
            else {
                dateFrom[header] = "";
            }
        }

        selectAllRows[header] = false;
        //this.selectAll(header)

        for (var i = globalFiltersArray.length - 1; i > -1; i--) {
            if (globalFiltersArray[i].field === header) {
                if (from === "column") {
                    globalFilter[globalFiltersArray[i].field] = false;
                    globalFilterText[globalFiltersArray[i].field] = "";
                    globalFiltersArray.splice(+i, 1);
                }
                else {
                    if (globalFiltersArray[i].filterType === filterType) {
                        globalFilter[globalFiltersArray[i].field] = false;
                        globalFilterText[globalFiltersArray[i].field] = "";
                        globalFiltersArray.splice(+i, 1);
                    }
                }

            }
        }

        this.numberSelectedCheck = numberSelectedCheck;
        return [groupedColumn, alreadyGrouped, filterText, filtered, numberSelectedCheck, globalFilterText, numericMin, numericMax, dateTo, dateFrom, selectAllRows, globalFiltersArray, globalFilter]
    }

    returnMatchingFilters(filterSliceStream, matchingArray, groupField, matchingRecGroup, matchingSignedMeasure, selectedMatchingView, initRowGroupMetaData, filterOnMatchingRec, filterOnMatchingSize, filterOnSignedMeasure, filterMatchingRecANDOR, filterMatchingRecMin, filterMatchingRecMax, filterMatchingSizeANDOR, filterMatchingSizeMin, filterMatchingSizeMax, filterSignedMeasureANDOR, filterSignedMeasureMin, filterSignedMeasureMax) {

        const useFilter = arr => {
            return arr.filter((value, index, self) => {
                return self.indexOf(value) === index;
            });
        };


        for (var u = 0; u < matchingArray.length; u++) {
            let param = matchingArray[u].name;
            let operator = matchingArray[u].operator;
            let filterOn = "filterOn" + param;
            let filterMin = "filter" + param + "Min";
            let filterMax = "filter" + param + "Max";
            let filterANDOR = "filter" + param + "ANDOR";

            if (eval(filterOn)) {
                //  let groupField = groupField;

                let matchingFilters = [];

                if ((param === 'SignedMeasure') || (param === 'MatchingRec')) {
                    let keys = filterSliceStream.map(function (el) {
                        return el[groupField];
                    })
                    keys = useFilter(keys);


                    for (var j in keys) {
                        let key = +keys[j];
                        let filteredData = filterSliceStream.filter(item => item[groupField] === key);

                        let newdata;

                        if (param === 'MatchingRec') {
                            let matchingField = matchingRecGroup;
                            if (matchingRecGroup === selectedMatchingView) {
                                matchingField = "cardabel_rec";
                            }
                            let mappedData = filteredData.map(item => item[matchingField]);
                            console.log(filteredData);
                            console.log(mappedData)
                            // Using Array.filter

                            const result = useFilter(mappedData);
                            console.log(result);

                            newdata = {
                                key: key,
                                diffItems: result.length
                            }

                        }
                        else {

                            newdata = {
                                key: key,
                                sum: filteredData.reduce((accumulator, current) => accumulator + current[matchingSignedMeasure], 0)
                            }
                        }
                        matchingFilters.push(newdata);
                    }
                }

                if (param === "MatchingSize") {
                    let keys = Object.keys(initRowGroupMetaData);
                    for (var m in keys) {
                        let key = +keys[m];
                        let newdata = {
                            key: key,
                            size: initRowGroupMetaData[key].size
                        }

                        matchingFilters.push(newdata)
                    }
                }




                let min;
                if ((eval(filterMin)) && (eval(filterMin) != "")) {
                    min = +eval(filterMin);
                }
                else {
                    min = null;
                }
                let max;
                if ((eval(filterMax)) && (eval(filterMax) != "")) {
                    max = +eval(filterMax);
                }
                else {
                    max = null;
                }
                let condition = eval(filterANDOR);

                matchingFilters = matchingFilters.filter(item => {
                    if (min != null && max === null) {
                        if (Math.abs(item[operator]) >= min) {
                            return true;
                        }
                        else return false;
                    }
                    else if (min === null && max != null) {
                        if (Math.abs(item[operator]) <= max) {
                            return true;
                        }
                        else return false;
                    }
                    else if (min === null && max === null) {
                        return true;
                    }
                    else if (condition === true) {
                        if ((Math.abs(item[operator]) >= min) && (Math.abs(item[operator]) <= max)) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                    else {
                        if ((Math.abs(item[operator]) <= min) || (Math.abs(item[operator]) >= max)) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                })

                let filteredKeys = matchingFilters.map(function (el) { return el.key });

                filterSliceStream = filterSliceStream.filter(function (item) {
                    if (filteredKeys.indexOf(item[groupField]) > -1) {
                        return true;
                    }
                    else {
                        return false;
                    }
                })

            }

        }

        return filterSliceStream;

    }

    formatBookmarkFilters(filterField, filterValue, filterType, filterValue2, filterCondition, inputType, filter, selectedRows, inNotCondition, filterText, clickFilter, numericFilterAndOr, numericMin, numericMax, dateTo, dateFrom, numberSelectedCheck) {
        if (filterType === "in") {
            filter[filterField] = inputType + "Keyboard";
            for (var k in filterValue) {
                selectedRows[filterField][filterValue[k]] = true;
            }
            inNotCondition[filterField] = filterCondition;
            numberSelectedCheck[filterField] = filterValue.length;
            // this.closeMenu(filterField, inputType);
        }
        else if (inputType === "string") {
            if ((filterType != 'empty') && (filterType != 'notempty')) {
                filterText[filterField] = filterValue;
            }
            clickFilter[filterField] = filterType;
        }
        else if (inputType === "numeric") {
            numericFilterAndOr[filterField] = filterCondition;

            if (filterType === "greater") {
                numericMin[filterField] = filterValue;
                numericMax[filterField] = filterValue2;
            }
            else if (filterType === "less") {
                numericMin[filterField] = filterValue2;
                numericMax[filterField] = filterValue;
            }

        }
        else if (inputType === "date") {
            if (filterType === "dateTo") {
                dateTo[filterField] = new Date(filterValue);
                dateFrom[filterField] = new Date(filterValue2);
            }
            else {
                dateTo[filterField] = new Date(filterValue2);
                dateFrom[filterField] = new Date(filterValue);
            }
        }

        return [filter, selectedRows, inNotCondition, filterText, clickFilter, numericFilterAndOr, numericMin, numericMax, dateTo, dateFrom, numberSelectedCheck]
    }

}