/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./true-status-type.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/settings-table-body/settings-table-body.component.ngfactory";
import * as i3 from "../shared/settings-table-body/settings-table-body.component";
import * as i4 from "../../../app.service";
import * as i5 from "primeng/components/common/confirmationservice";
import * as i6 from "./true-status-type.component";
import * as i7 from "./true-status-type.service";
import * as i8 from "../../../shared/shared-common-initialization.service";
var styles_TrueStatusTypeComponent = [i0.styles];
var RenderType_TrueStatusTypeComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_TrueStatusTypeComponent, data: {} });
export { RenderType_TrueStatusTypeComponent as RenderType_TrueStatusTypeComponent };
export function View_TrueStatusTypeComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { trueStatusTypesTable: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "col-xs-12 col-md-12 col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "pi pi-question-circle piHelp"], ["id", "helpIcon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getHelpDocumentation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "col-xs-12 col-md-12 col-sm-12 justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "col-xs-10 offset-xs-1 col-sm-6 offset-sm-3 col-md-4 offset-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h3", [["class", "sectionTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["True status types"])), (_l()(), i1.ɵeld(9, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 10, "div", [["class", "col-md-10 col-xs-12 col-sm-12 offset-md-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 9, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "btn-group applyButtonGroup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-primary btn-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.applyTrueStatusType() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save changes"])), (_l()(), i1.ɵeld(17, 0, null, null, 3, "div", [["class", "row margin-top-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "div", [["class", "col-xs-12 col-md-8 col-sm-12 offset-md-2 justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "app-settings-table-body", [], null, null, null, i2.View_SettingsTableBodyComponent_0, i2.RenderType_SettingsTableBodyComponent)), i1.ɵdid(20, 638976, [[1, 4], ["trueStatusTypesTable", 4]], 0, i3.SettingsTableBodyComponent, [i4.AppService, i5.ConfirmationService], { tableData: [0, "tableData"], groupField1: [1, "groupField1"], groupField2: [2, "groupField2"], title1: [3, "title1"], title2: [4, "title2"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.trueStatusData; var currVal_1 = _co.groupField1; var currVal_2 = _co.groupField2; var currVal_3 = "Prefix"; var currVal_4 = "True Status Types"; _ck(_v, 20, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_TrueStatusTypeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-true-status-type", [], null, null, null, View_TrueStatusTypeComponent_0, RenderType_TrueStatusTypeComponent)), i1.ɵdid(1, 245760, null, 0, i6.TrueStatusTypeComponent, [i7.TrueStatusTypeService, i8.SharedInitializationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TrueStatusTypeComponentNgFactory = i1.ɵccf("app-true-status-type", i6.TrueStatusTypeComponent, View_TrueStatusTypeComponent_Host_0, {}, {}, []);
export { TrueStatusTypeComponentNgFactory as TrueStatusTypeComponentNgFactory };
